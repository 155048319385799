import React from "react";
import { connect } from "react-redux";
import Modal from "../HelpersComponents/Modal.Component";
import { showModal } from "../../actions/baseActions";
import ModalTypes from "../../enums/Modal.Types.Enum";
import { Spin } from "antd4";
import AddAddress from "../HelpersComponents/Add.Address.Component"
const LocationModal = ({ loading, locationModal, showModal, onAddressConfirmed }) => {
    return (
        <div>
            <Modal
                className={"Location_Modal HomeSearchComponent"}
                visible={locationModal}
                onCancel={() => showModal(ModalTypes.LOCATION_MODAL, false)}
            >
                <Spin spinning={loading}>
                    <div className="getFoodType">
                        <h2 className={'SignInTitle text-center'}>Please add your address</h2>
                        <h5>Please search address or another text will be here</h5>
                        <AddAddress onAddressConfirmed={onAddressConfirmed} />
                    </div>
                </Spin>
            </Modal>
        </div>
    );
};



const mapStateToProps = state => ({
    locationModal: state.modalVisible.locationModal,
    loading: state.loading
});

const mapDispatchToProps = { showModal };


export default connect(mapStateToProps, mapDispatchToProps)(LocationModal);
