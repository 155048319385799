import React, {useEffect} from 'react';
import ReviewsMaker from '../../container/ReviewsMaker/ReviewsMaker';
import {connect} from 'react-redux';
import heart from "../../img/heart.png";
import redHeart from "../../img/redHeart.png";
import {useHistory} from 'react-router';
import {addFavoritesProduct, removeFavoritesProduct} from "../../api/Product";
import {changeRestaurantIsFavorites} from "../../actions/baseActions";

const RestaurantCart = ({restaurant, isAuth, changeRestaurantIsFavorites}) => {
    const history = useHistory()
    if (!Object.keys(restaurant)?.length) return false
    const {
        downloadLink = "",
        name = "",
        rating = 0,
        reviewsCount = 0,
        address,
        showProductPriceAVG,
        showKitchenTypes,
        slug,
        isFavorite,
        workingHours
    } = restaurant.restaurant
    localStorage.setItem('restaurantSlug', slug)
    return <div className='restaurant_cart_item restaurant_items_Wrapper'>
        <div className="item">
            <div
                onClick={() => {
                    history.push(`restaurant-menu/${slug}`)
                }}
                className={"restaurantImage"}
                style={{backgroundImage: `url(${downloadLink})`}}
            ></div>
            {isAuth &&
                <img
                    src={!isFavorite ? heart : redHeart}
                    className="heart"
                    alt="heart"
                    onClick={() => {
                        if (!isFavorite) {
                            addFavoritesProduct(slug, "restaurant").then(() => changeRestaurantIsFavorites())
                        }
                        if (isFavorite) {
                            removeFavoritesProduct(restaurant.slug).then(() => changeRestaurantIsFavorites())
                        }
                    }}/>}
            <div className='restaurant_content' onClick={() => {
                history.push(`restaurant-menu/${slug}`)
            }}>
                <p onClick={() => {
                }}>{name}</p>
                <div className='location'><span>{address?.address}</span></div>
                <span className='price_avg'>{showKitchenTypes}</span>
                <span>Open {workingHours[new Date().getDay()]?.times[0].openTime}AM - {workingHours[new Date().getDay()]?.times[0].closeTime}PM</span>
                <div className="stars">
                    <ReviewsMaker
                        rating={rating}
                        count={reviewsCount}
                    />
                    <span className='price_avg'> | {showProductPriceAVG}</span>
                </div>
            </div>
        </div>
    </div>
}
const mapStateToProps = state => ({
    restaurant: state.selectedRestaurant,
    isAuth: state.user.isAuth
});

const mapDispatchToProps = {
    changeRestaurantIsFavorites
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RestaurantCart);
