import React, { useEffect, useState } from "react";


const Faq = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    }, []);
    const items = [
        {
            id: 1,
            header: "General",
            items: [
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
            ]
        },
        {
            id: 2,
            header: "Shipping & Returns",
            items: [
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
            ]
        },
        {
            id: 3,
            header: "Warrantly",
            items: [
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
            ]
        },
        {
            id: 4,
            header: "Payment",
            items: [
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
                {
                    header: "Lorem Ipsum is simple  text of the printing and typesetting",
                    body: "Lorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesettingLorem Ipsum is simple  text of the printing and typesetting"
                },
            ]
        },
    ];
    const [showNumber1, setShowNumber1] = useState(null);
    const [showNumber2, setShowNumber2] = useState(null);
    return <div className="faq_wrapper">
        {
            items.map(item => {
                return <div key={item.id} className="item_wrapper">
                    <div className="item_header">{item.header}</div>

                    {item.items.map((text, key) => {
                        return <div className="item" style={showNumber2 === key && showNumber1 === item.id ? { height: "auto" } : { height: "65px" }} >
                            <h1 style={showNumber2 === key && showNumber1 === item.id ? { color: "#FF5722" } : { color: "#212121" }}>{text.header}</h1>
                            <h2 style={showNumber2 === key && showNumber1 === item.id ? { display: "block" } : { display: "none" }}>{text.body}</h2>
                            <span onClick={() => {
                                if (showNumber2 === key) {
                                    setShowNumber1(null);
                                    setShowNumber2(null)
                                } if (showNumber2 !== key) {

                                    setShowNumber1(item.id);
                                    setShowNumber2(key)
                                }
                            }}>{showNumber2 === key && showNumber1 === item.id ? "-" : "+"}</span>
                        </div>
                    })}

                </div>
            })
        }
    </div>
}
export default Faq