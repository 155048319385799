import React from 'react';
import {useState, useEffect} from "react";
import undraw from "../../../img/undraw.png";
import close from "../../../img/close.png";
import {getCouponPercent} from "../../../api/Home.API";
import ModalWrapper from '../../ModalWrapper/ModalWrapper';

const MyCoupons = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    }, []);
    const [coupons, setCoupons] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalValue, setModalValue] = useState("");
    const [uncorrectCode, setUncorrectCode] = useState(true);

    const modal = () => {
        let modal = <div className='modal'>
            <div>
                <span>Add new promotion</span>
                <img src={close} alt="close" onClick={() => {
                    setModalShow(false)
                }}/>
            </div>
            <input
                type="text"
                placeholder='Enter promo code'
                value={modalValue}
                onChange={(e) => setModalValue(e.target.value)}/>
            {!uncorrectCode ? <span className='not_valid'>Promtion code is not vaid</span> : null}
            <button className={!!modalValue.length ? "active" : ""} onClick={() => [
                getCouponPercent({
                    "code": modalValue,
                    "restaurantSlug": "birds-hollywood"
                }).then(d => setUncorrectCode(d))
            ]}>Add promo
            </button>
        </div>
        return modal
    }
    return <div className='my_coupon_wrapper'>
        {!modalShow && <button onClick={() => setModalShow(true)}>Add New</button>}
        {
            !coupons.length
                ?
                <div className='no_favorete_products'>
                    <img alt="undraw" src={undraw}/>
                    <div style={{width: "200px", textAlign: "center"}}>There is no coupons for you</div>
                </div>
                :
                <div className='coupon_items'>
                    {
                        coupons.map(coupon => {
                            return (
                                <div className='coupon_items_wrapper'>
                                    <p>{coupon.header}</p>
                                    <h1>{coupon.desc}</h1>
                                    <div className='coupon_desc'>
                                        <img src={coupon.img} alt="coupon"/>
                                        <span className='coupon_percent'>{coupon.percent}% OFF</span>
                                        <div>
                                            <span>Expiration Date:</span>
                                            <span>{coupon.date}</span>
                                        </div>
                                        <div>
                                            <span>Coupon Code</span>
                                            <span>{coupon.couponCode}</span>
                                        </div>
                                    </div>
                                </div>)
                        })}
                </div>

        }

        {modalShow
            &&
            <ModalWrapper
                height={window.pageYOffset}
                zIndex={0}
                showModal={modalShow}
                closeModal={() => setModalShow(false)}
            >
                {modal()}
            </ModalWrapper>}
    </div>
}
export default MyCoupons