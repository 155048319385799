import React, { useEffect } from "react";
import { connect } from "react-redux";
import ProductItem from "./ProductItem";
import { clearProductList, getProducts } from "../../../actions/baseActions";
import "./../../../styles/RegularMenuStyle/regularMenu.scss";
import { addPageCount } from "../../../actions/pageCountAction";

const ProductList = ({
    products = [],
    addPageCount,
    getProducts,
    pageCount,
    categories,
    filter,
    address,
    isDelivery,
    setLocalIsFavorite,
    localIsFavorite,
    isAuth,
    clearProductList,
}) => {
    useEffect(() => {
        let resFilter = { ...filter };
        resFilter.categories = filter.selectedCategories;
        delete resFilter.isFiltered;
        delete resFilter.distanceMax;
        delete resFilter.deliveryTime;
        delete resFilter.cal;
        if (!resFilter.isOpenNow) {
            delete resFilter.isOpenNow;
        }
        if (!address && isDelivery) {
            clearProductList();
        } else if (!isDelivery) {
            if (filter.priceMin !== null && filter.priceMax !== null) {
                getProducts(pageCount, 12, { ...resFilter }, isDelivery, isAuth);
            }
        } else if (address && isDelivery) {
            if (filter.priceMin !== null && filter.priceMax !== null) {
                getProducts(pageCount, 12, { ...resFilter }, isDelivery, isAuth);
            }
        }
    }, [
        pageCount,
        filter.orderBy,
        filter.dir,
        filter.searchText,
        filter.selectedCategories,
        filter.priceMin,
        filter.priceMax,
        address,
        isDelivery,
        JSON.stringify(filter.isOpenNow),
    ]);
    useEffect(() => {
        return () => {
            clearProductList();
        };
    }, [isDelivery]);
    const showMoreButton = () => {
        let count = 0;
        let button = (
            <div className="show_more_button">
                <button onClick={addPageCount}>View More</button>
            </div>
        );
        categories.map((i) => {
            if (filter.selectedCategories.filter((j) => j === i.slug).length) {
                return (count += i.productsCount);
            }
        });

        if ((count && products.length < count) || !count) {
            return button;
        } else {
            return null;
        }
    };
    return products.length ? (
        <div className="product_list_wrapper">
            <div className="product_list_header">Food</div>
            <div className="itemsWrapper">
                {products.map((item, k) => (
                    <ProductItem key={k} item={item} setLocalIsFavorite={setLocalIsFavorite} localIsFavorite={localIsFavorite} />
                ))}
            </div>
            {showMoreButton()}
        </div>
    ) : null;
};
const mapStateToProps = (state) => ({
    products: state.products.items,
    pageCount: state.pageCount,
    filter: state.filter,
    address: state.currentLocation.address.address,
    categories: state.categories,
    isDelivery: state.isDelivery,
    isAuth: state.user.isAuth,
});

const mapDispatchToProps = {
    getProducts,
    addPageCount,
    clearProductList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
