import React from 'react';
import { withRouter } from "react-router";
import Modal from "../HelpersComponents/Modal.Component";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Spin } from 'antd4';
import { Roles as ServerRoles } from '../../enums/Server.Sider.Roles.enum';
import PartnerTabs from '../../enums/Partner.Tabs.Enum';
import Paths from "../../services/Paths";
import { hasRole } from '../../services/Utilities';
import SignInForm from '../SecurityComponents/SignIn/Sign.In.Form.Component';

import { showModal } from '../../actions/baseActions';
import ModalTypes from "../../enums/Modal.Types.Enum";

export const SignModal = ({ signInModalVisible, showModal, basketModalVisible, loading, history }) => {
	const registerUser = () => {
		showModal(ModalTypes.SIGN_IN_MODAL, false);
		showModal(ModalTypes.REGISTRATION_MODAL);
	};

	const forgotPasswordRequest = () => {
		showModal(ModalTypes.SIGN_IN_MODAL, false);
		showModal(ModalTypes.FORGOT_PASSWORD_MODAL);
	};

	const successSignIn = user => {
		if (hasRole(user, [ServerRoles.ROLE_PARTNER])) {
			history.push(Paths.basePartner.replace(':tabName', PartnerTabs.orders));
		}

		if (hasRole(user, [ServerRoles.ROLE_CLIENT]) && basketModalVisible) {
			showModal(ModalTypes.BASKET_MODAL, false)
			history.push(Paths.order)
		}
	};
	return (
		<>
			<Modal
				className={"SignIn"}
				visible={signInModalVisible}
				onCancel={() => showModal(ModalTypes.SIGN_IN_MODAL, false)}
			>
				<Spin spinning={loading}>
					<h2 className={'SignInTitle text-center'}>Log in </h2>
					<div>
						<SignInForm
							onSuccessSignIn={successSignIn}
							onForgotPasswordClick={forgotPasswordRequest}
							onRegisterClick={registerUser}
						/>
					</div>

				</Spin>
			</Modal>
			
		</>
	)
};

SignModal.propTypes = {
	signInModalVisible: PropTypes.bool.isRequired,
	showModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	signInModalVisible: state.modalVisible.signIn,
	loading: state.loading,
	basketModalVisible: state.modalVisible.basket
});

const mapDispatchToProps = {
	showModal
};

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(SignModal));
