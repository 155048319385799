import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import {
    showModalRegularMenu,
    setSelectedProduct,
    setLocalItems,
    setLocalSelectedRestaurantProduct
} from "../../actions/baseActions";
import { addFirstItem, clearBasketAction } from '../../actions/basketActions';
import "../../styles/PopUp/Popup.scss";
import heart from "../../img/heartForPopUp.png";
import redHeart from "../../img/redHeart.png";
import close from "../../img/close.png";
import { addProductToBusket } from '../../api/Basket.API';
import { messageType, showMessage } from '../../services/Utilities';
import { addFavoritesProduct, removeFavoritesProduct } from '../../api/Product';
import { useHistory, useParams } from "react-router-dom";
import { changeStep } from '../../actions/checkoutAction';
import v from "../../img/v.png"
import { Collapse, Radio } from 'antd4';
import { Checkbox } from 'antd4';
import { scrollHidden } from '../../settings/scrollHidden';
const { Panel } = Collapse;
const PopUP = ({
    selectedProduct,
    items,
    changeStep,
    showModalRegularMenu,
    setLocalItems,
    setSelectedProduct,
    clearBasketAction,
    addFirstItem,
    height,
    isAuth
}) => {
    const history = useHistory()
    const { restaurantSlug } = useParams()

    const {
        name,
        downloadLink,
        isFavorite,
        restaurant,
        slug,
        price,
        description,
        ingredients = [],
        sizeGroups = []
    } = selectedProduct;
    const [selectAllRequiredItem, setSelectAllRequiredItem] = useState(false);
    const [count, setCount] = useState(1)
    const [ingredientSelected, setIngredientSelected] = useState([]);
    const [ingredientSelectedForSize, setIngredientSelectedForSize] = useState([]);
    const [endPrice, setEndPrice] = useState(price);
    const [textareaValue, setTextAreaValue] = useState("");
    const [localIsFavorite, setLocalIsFavorite] = useState(isFavorite);
    const [error, setError] = useState(false);
    const [isCartClear, setIsCartClear] = useState(false);
    const [isRequared, setIsRequared] = useState(0);
    const [isRequaredSize, setIsRequaredSize] = useState(0);
    const SetCountFunc = (c) => {
        if (count > 1 && c === -1) {
            return setCount(count - 1)
        }
        if (c === 1) {
            return setCount(count + 1)
        }
    }
    console.log(ingredientSelectedForSize);
    useEffect(() => {
        if (sizeGroups.length) {
            let newSizeGroup = sizeGroups.map(element => {
                return {
                    id: element.slug,
                    name: element.name,
                    isRequared: true,
                    selectableCounts: element.selectableCounts ? element.selectableCounts : 1,
                    size: element.size.map(item => {
                        return {
                            id: item.id,
                            slug: item.slug,
                            isChecked: false,
                            name: item.name,
                            plusPrice: item.plusPrice ? item.plusPrice : 0
                        }
                    })
                }
            });
            setIngredientSelectedForSize(newSizeGroup)
        }
        if (ingredients.length) {
            let iR = 0;
            let newIngredients = ingredients.map((element) => {
                if (element.isRequired) {
                    if (element.selectableCounts) {
                        iR += element.selectableCounts
                    } else {
                        iR += 1
                    }
                }
                return {
                    id: element.slug,
                    name: element.name,
                    selectableCounts: element.selectableCounts ? element.selectableCounts : element.ingredient.length,
                    isRequared: element.isRequired,
                    ingredient: element.ingredient.map(item => {
                        return {
                            id: item.id,
                            slug: item.slug,
                            isChecked: false,
                            name: item.name,
                            plusPrice: item.plusPrice ? item.plusPrice : 0
                        }
                    })

                }
            });
            setIsRequared(iR)
            setIngredientSelected(newIngredients)
        }
    }, [sizeGroups, ingredients]);
    useEffect(() => {
        scrollHidden(true)
        return (() => {
            scrollHidden(false)
            setSelectedProduct({});
            setError(false);
        })
    }, [setSelectedProduct]);
    useEffect(() => {
        if (localIsFavorite !== isFavorite) {
            if (localIsFavorite) {
                addFavoritesProduct(slug)
            }
            if (!localIsFavorite) {
                removeFavoritesProduct(slug)
            }
            if (!restaurantSlug) {
                let newItems = [...items];
                newItems[newItems.findIndex(i => i.slug === slug)].isFavorite = localIsFavorite
                setLocalItems(newItems)
            }
        }
    }, [isFavorite, items, localIsFavorite, restaurantSlug, setLocalItems, slug]);

    const AddToBasket = () => {
        if (isRequared > 0) {
            setSelectAllRequiredItem(true)
            return false
        }
        let ingredients = [];
        ingredientSelected.map(i => {
            return i.ingredient.map(j => {
                if (j.isChecked) {
                    ingredients.push(j.slug)
                }
            })
        })
        addProductToBusket(slug,
            {
                count,
                comment: textareaValue,
                size: ingredientSelectedForSize && ingredientSelectedForSize[0] ? ingredientSelectedForSize[0].size.filter(element => element.isChecked)[0]?.slug : "",
                ingredients: ingredients
            }).then(i => {
                if (+i.code === 404) {
                    showMessage(messageType.info, i.message["message-0"])
                } else if (+i.code === 200) {
                    showMessage(messageType.success, i.message["message-0"])
                    showModalRegularMenu()
                    addFirstItem()
                }
            });
    }
    const radioClick = (e, i, j, a, b) => {
        if (e.target.checked) {
            let price = +endPrice;
            if (i.size.filter(item => item.isChecked).length) setIsRequaredSize(isRequaredSize - 1)
            let stateCopy = [...ingredientSelectedForSize];
            let count = i.selectableCounts;
            let checkedCount = i.size.filter(n => n.isChecked)
            stateCopy[j].size.forEach((element) => {
                if (element?.plusPrice && element.id !== a.id && element.isChecked) price = price - +element.plusPrice
                if (element?.plusPrice && element.id === a.id && !element.isChecked) price = price + +element.plusPrice
            })
            if (checkedCount.length < count && stateCopy[j].size[b].isChecked === false) {
                stateCopy[j].size[b].isChecked = !stateCopy[j].size[b].isChecked
                setIngredientSelectedForSize([...stateCopy])
            } else if (stateCopy[j].size[b].isChecked === true) {
                stateCopy[j].size[b].isChecked = !stateCopy[j].size[b].isChecked
                setIngredientSelectedForSize([...stateCopy])
            }
            if (count === 1) {
                stateCopy[j].size.forEach((element, index) => element.isChecked = stateCopy[j].size[index] === stateCopy[j].size[b])
                setIngredientSelectedForSize([...stateCopy])
            }
            if (i.isRequared) {
                if (i.size.filter(item => item.isChecked).length < 2) {
                    a.isChecked && setIsRequared(prev => prev - 1)
                }
                if (i.size.filter(item => item.isChecked).length < 1) {
                    !a.isChecked && setIsRequared(prev => prev + 1)
                }
            }
            setEndPrice(price)
        }
    }
    const checkClick = (e, i, j, a) => {
        setSelectAllRequiredItem(false)
        let stateCopy = [...ingredientSelected];
        let price = +endPrice;
        if (i.isRequared && i.ingredient.filter(item => item.isChecked).length < 1) {
            setIsRequared(isRequared - 1)
        }
        stateCopy[j].ingredient.forEach((element) => {
            if (element.id !== a.id && element.isChecked === true) {
                element.isChecked = false;
                if (element?.plusPrice) {
                    price = price - +element.plusPrice
                }
            }
            if (element.id === a.id && element.isChecked === false) {
                element.isChecked = true;
                if (element?.plusPrice) {
                    price = price + +element.plusPrice
                }
            }
        })
        setEndPrice(price)
        setIngredientSelected([...stateCopy])
    }
    const checkClick2 = (e, i, j, a, b) => {
        let stateCopy = [...ingredientSelected];
        let count = i.selectableCounts;
        let checkedCount = i.ingredient.filter(n => n.isChecked)
        if (checkedCount.length < count && stateCopy[j].ingredient[b].isChecked === false) {
            if (a?.plusPrice) {
                setEndPrice(endPrice + +a.plusPrice)
            }
            stateCopy[j].ingredient[b].isChecked = !stateCopy[j].ingredient[b].isChecked
            setIngredientSelected([...stateCopy])
        } else if (stateCopy[j].ingredient[b].isChecked === true) {
            if (a?.plusPrice) {
                setEndPrice(endPrice - +a.plusPrice)
            }
            stateCopy[j].ingredient[b].isChecked = !stateCopy[j].ingredient[b].isChecked
            setIngredientSelected([...stateCopy])
        }
        if (count === 1) {
            stateCopy[j].ingredient.forEach((element, index) => {
                element.isChecked = stateCopy[j].ingredient[index] === stateCopy[j].ingredient[b];
            })
            setIngredientSelected([...stateCopy])
        }
        if (i.isRequared) {
            if (i.ingredient.filter(item => item.isChecked).length < 2 || i?.selectableCounts) {
                a.isChecked && setIsRequared(prev => prev - 1)
            }
            if (i.ingredient.filter(item => item.isChecked).length < 1 || i?.selectableCounts) {
                if (i?.selectableCounts) {
                    if (i.ingredient.filter(item => item.isChecked).length < i?.selectableCounts) {
                        !a.isChecked && setIsRequared(prev => prev + 1)
                    }
                } else {
                    !a.isChecked && setIsRequared(prev => prev + 1)
                }
            }
        }
    }
    return (
        <div className='pop_up_wrapper' style={{ top: height }}>
            <div className='pop_up_header'>
                <span>Item Information</span>
                <span onClick={() => {
                    showModalRegularMenu();
                    setSelectedProduct({})
                }}><img src={close} alt={close} /></span>
            </div>
            {selectAllRequiredItem &&
                <div className='pop_up_is_close'><span>Please select all required field</span></div>
            }
            {error &&
                <div className='pop_up_error'>
                    <div className='pop_up_error_body'>You can’t add this item to your cart, because it’s from another
                        restaurant. If you want to buy this item, you must clear your cart or complete first
                        one.{error.message}</div>
                    <div className='pop_up_error_footer'>
                        <button onClick={() => {
                            clearBasketAction().then(() => {
                                setError(false)
                                setIsCartClear(true)
                            })
                        }}>Clear cart
                        </button>
                        <button onClick={() => {
                            changeStep(2)
                            history.push("/shopping-cart");
                        }}>Checkout
                        </button>
                    </div>
                </div>
            }
            {
                !restaurant?.openNow &&
                <div className='pop_up_is_close'>
                    <span>This restaurant is temporary closed, this  item is unaveilable right now, but you can choose schedule option.</span>
                    <button>Schedule</button>
                </div>
            }
            {isCartClear &&
                <div className='pop_up_is_cart_clear'>
                    <div><img src={v} alt="v" /></div>
                    <span>Your cart is clear</span>
                </div>
            }
            <div className='pop_up_body'>
                <div
                    className='pop_up_picture'
                    style={{ backgroundImage: `url(${downloadLink})` }}></div>
                <div className='pop_up_information'>
                    <div className='pop_up_info_header'>
                        <span className="pop_up_product_name">{name}
                        </span>
                        <span className='pop_up_price'>${price}</span>
                        {isAuth && <img
                            alt="heart"
                            src={!localIsFavorite ? heart : redHeart}
                            onClick={() => {
                                if (!localIsFavorite) setLocalIsFavorite(true)
                                if (localIsFavorite) setLocalIsFavorite(false)
                            }}
                        />}
                    </div>
                    <div className='pop_up_desc'>{description}</div>
                    <div className='pop_up_footer'>
                        <span onClick={() => SetCountFunc(-1)}>-</span>
                        <span className='pop_up_count'>{count}</span>
                        <span onClick={() => SetCountFunc(1)}>+</span>
                    </div>
                    <div className='pop_up_selects_area'>
                        <div className='pop_up_select_area_right'>
                            <div className='pop_up_textarea'>
                                <span className='area_header'>Note:</span>
                                <textarea placeholder='Type here' value={textareaValue} onChange={(e) => {
                                    setTextAreaValue(e.target.value)
                                }}></textarea>
                            </div>
                        </div>
                        {ingredientSelected.length ?
                            <div className='pop_up_select_area_left'>
                                <Collapse accordion>
                                    <Panel header="Extra Sauces">
                                        {ingredientSelected.map((i, j) => {
                                            return <div className='souces_list' key={i.slug}>
                                                <div className='souces_list_type'>{i.name}</div>
                                                {i.ingredient && i.ingredient.map((a, b) => {
                                                    return (
                                                        <div
                                                            className='souces_list_item'
                                                            key={b}
                                                        >{i.selectableCounts === 1
                                                            ?
                                                            <Radio
                                                                onClick={e => checkClick(e, i, j, a)}
                                                                checked={a.isChecked}
                                                                type='checkbox'
                                                            >
                                                                {a.name}
                                                            </Radio>
                                                            :
                                                            <Checkbox checked={a.isChecked}
                                                                onClick={(e) => checkClick2(e, i, j, a, b)}
                                                                type='checkbox'
                                                            >
                                                                {a.name}
                                                            </Checkbox>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        })}
                                    </Panel>
                                    {ingredientSelectedForSize.length && ingredientSelectedForSize.map((i, j) => {
                                        return (
                                            <Panel header={i.name} key={2}>
                                                {i.size && i.size.map((a, b) => {
                                                    return (
                                                        <div
                                                            className='souces_list_item'
                                                            key={b}>
                                                            <Radio
                                                                onClick={(e) => radioClick(e, i, j, a, b)}
                                                                checked={a.isChecked}
                                                                type='checkbox'
                                                            > {a.name}
                                                            </Radio>
                                                        </div>
                                                    )
                                                })}
                                            </Panel>
                                        )
                                    })}
                                </Collapse>
                            </div> : null}
                    </div>
                </div>
            </div >
            <div className='add_button'>
                <button disabled={isRequared > 0 || isRequaredSize > 0} onClick={AddToBasket}>
                    Add to cart ${count * Math.round(endPrice * 100) / 100}
                </button>
            </div>
        </div >
    )
}
const mapStateToProps = state => ({
    selectedProduct: state.regularMenu.selectedProduct,
    showModal: state.regularMenu.showModal,
    items: state.products.items,
    isAuth: state.user.isAuth,
    width: state.width
});

const mapDispatchToProps = {
    setSelectedProduct,
    showModalRegularMenu,
    addFirstItem,
    changeStep,
    clearBasketAction,
    setLocalItems,
    setLocalSelectedRestaurantProduct
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(React.memo(PopUP));