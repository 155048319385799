import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import "../../styles/ShippingPayment/ShippingPayment.scss";
import OrderSummary from "./OrderSummary";
import { checkoutComplet, tipTarif } from "../../api/Payment.API";
import { messageType, showMessage } from "../../services/Utilities";
import DeliveryOption from "./DeliveryOption";
import ArrivalTime from "./ArrivalTime";
import UserInfo from "./UserInfo";
import CreditCard from "./CreditCard";
import { changeStep, sendUuidCode } from "../../actions/checkoutAction";
import ModalWrapper from "./../ModalWrapper/ModalWrapper";
import DonateModal from "./DonateModal";
import { refactorCardDate } from "./../../services/Utilities";
import { showLocalLoading } from "../../actions/baseActions";
import NavigateButtons from "./NavigateButton";
import ModalTypes from "../../enums/Modal.Types.Enum";
import { showModal } from "../../actions/baseActions";

const checkNumber = (number) => {
  if (
    number === "0" ||
    number === "1" ||
    number === "2" ||
    number === "3" ||
    number === "4" ||
    number === "5" ||
    number === "6" ||
    number === "7" ||
    number === "8" ||
    number === "9"
  ) {
    return true;
  } else return false;
};

const ShoppingPayment = ({
  changeStep,
  showLocalLoading,
  user,
  sendUuidCode,
  isAuth,
  scheduleDates,
  schedule,
  donateProps,
  userInfo,
  location,
  isDelivery,
  subTotal,
  showModal: showAddresModal,
}) => {
  const [persent, setPersent] = useState([]);
  const [tip, setTip] = useState(0);
  const [customTip, setCustomTip] = useState("");
  const [donate, setDonate] = useState("");
  const [selectedPercent, setSelectedPercent] = useState({
    percentage: 23,
    isDefault: false,
  });
  const [selectedPaymantMethod, setSelectedPaymantMethod] = useState(0);
  const [cashDonete, setCashDonete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [creditcard, setCreditcard] = useState({
    expDate: "",
    showNumber: "",
    holderName: "",
    cvv: "",
    save: false,
    id: null,
  });
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    tipTarif().then(({ data }) => {
      setPersent(data);
      let isDefault = data.filter((i) => i.isDefault);
      setSelectedPercent(isDefault[0]);
    });
  }, []);

  useEffect(() => {
    const { expDate, showNumber } = creditcard;
    if (expDate?.length >= 5 && showNumber?.length >= 15) setDisabled(false);
    else setDisabled(true);
  }, [creditcard]);

  useEffect(() => {
    if (
      schedule &&
      scheduleDates.time.length > 4 &&
      scheduleDates.date.length > 8
    )
      setDisabled(false);
    if (schedule) {
      if (scheduleDates.time.length < 4 && scheduleDates.date.length < 8) {
        setDisabled(true);
      }
    } else setDisabled(true);
  }, [schedule, scheduleDates]);

  useEffect(() => {
    if (!location.address && isDelivery) {
      showAddresModal(ModalTypes.LOCATION_MODAL, true);
    }
  }, [location.address, isDelivery]);

  useEffect(() => {
    let newTip = 0;
    if (
      selectedPaymantMethod === 0 &&
      !cashDonete &&
      selectedPercent.percentage !== 0
    ) {
      newTip = Math.ceil(selectedPercent.percentage * subTotal) * 0.01;
      setTip(newTip);
    }
    if (selectedPaymantMethod === 1) {
      newTip = customTip;
      setTip(newTip);
    }
  }, [
    selectedPercent,
    selectedPaymantMethod,
    customTip,
    subTotal,
    persent,
    cashDonete,
  ]);

  useEffect(() => {
    if (selectedPaymantMethod === 0) {
      let isDefault = persent.filter((i) => i.isDefault);
      isDefault.length && setSelectedPercent(isDefault[0]);
      setCustomTip("");
    }
  }, [selectedPaymantMethod, persent]);

  const getCardsInfo = (value, type, creditcardObj) => {
    if (Object.keys(creditcardObj).length) {
      setCreditcard({
        ...creditcard,
        ...creditcardObj,
      });
    } else {
      let newObj = {
        ...creditcard,
        [type]: value,
      };
      delete newObj.id;
      setCreditcard({
        ...creditcard,
        [type]: value,
      });
    }
  };
  const handleCheckout = () => {
    let newCardData = !creditcard.id
      ? {
          number: creditcard.showNumber.replaceAll(" ", ""),
          expDate: refactorCardDate(creditcard.expDate),
          holderName: creditcard.holderName,
          cvv: creditcard.cvv,
          zip: "0965",
          saveCard: creditcard.save,
        }
      : {
          id: creditcard.id,
        };
    let sendData = {
      schedule: false,
      fullName: !isAuth
        ? `${userInfo.firstName} ${userInfo.lastName}`
        : `${user.firstName} ${user.lastName}`,
      phone: !isAuth ? userInfo.phoneNumber : user.phone,
      notes: userInfo.orderNotes || "",
      isDelivery,
      email: !isAuth ? userInfo.email : user.email,
      donateAmount: donate,
      creditCard: {
        ...newCardData,
      },
      tipType: selectedPaymantMethod,
      tipAmount: tip,
      location: location,
    };
    if (!isDelivery) delete sendData.location;
    if (!isAuth) sendData.createAccaunt = userInfo.createAccaunt;
    if (scheduleDates.date && scheduleDates.time && isDelivery) {
      sendData.schedule = true;
      sendData.scheduleDate = `${scheduleDates.date} ${scheduleDates.time}`;
    }
    showLocalLoading(true);
    checkoutComplet(sendData, isAuth).then((data) => {
      if (data.code === 400) {
        if (data?.message["message-0"] === "Invalid address supplied.") {
          showAddresModal(ModalTypes.LOCATION_MODAL, true);
        }
        showMessage(messageType.info, data?.message["message-0"]);
        showLocalLoading(false);
      }
      if (data.success) {
        sendUuidCode(data.data.order.uuid);
        changeStep(4);
        showLocalLoading(false);
      }
    });
  };

  return (
    <div className="shipping_payment_wrapper">
      <div className="shipping_payment_inputs_area">
        <div className="sp_option_wrapper">
          {isAuth && (
            <>
              <DeliveryOption />
              <ArrivalTime />
            </>
          )}
        </div>
        {isDelivery && <UserInfo />}
        <CreditCard creditcard={creditcard} getCardsInfo={getCardsInfo} />
        <div className="tip_wrapper">
          <p>Add a tip for your drvier</p>
          <div className="tip_with_credit">
            <div
              className={
                !selectedPaymantMethod
                  ? "tip_credit_card active"
                  : "tip_credit_card"
              }
              onClick={() => setSelectedPaymantMethod(0)}
            >
              Tip with credit card
            </div>
            {selectedPaymantMethod === 0 ? (
              <div className="tip_credit_card_percent">
                {persent.map((i, j) => {
                  if (i.percentage === 0) return false;
                  return (
                    <span
                      className={
                        i.percentage === selectedPercent.percentage &&
                        !cashDonete
                          ? "active"
                          : ""
                      }
                      key={j}
                      onClick={() => {
                        setSelectedPercent(i);
                        setCashDonete(false);
                        setCustomTip(0);
                      }}
                    >
                      {i.percentage}%
                    </span>
                  );
                })}
              </div>
            ) : null}
            <div
              className="donate_area"
              onClick={() => {
                if (donateProps !== true) setShowModal(true);
              }}
            >
              <span className={donateProps ? "active" : ""}>Donate</span>
              <input
                onChange={(event) => {
                  if (
                    checkNumber(
                      event.target.value[event.target.value.length - 1]
                    )
                  ) {
                    setDonate(event.target.value);
                  } else if (event.target.value === "") {
                    setDonate(event.target.value);
                  }
                }}
                type="text"
                step={0.5}
                disabled={!donateProps}
                min={0}
                value={donate}
              />
            </div>
          </div>
          <div className="tip_with_cash">
            <div
              className={
                selectedPaymantMethod === 1 ? "tip_cash active" : "tip_cash"
              }
              onClick={() => {
                setSelectedPercent(0);
                setSelectedPaymantMethod(1);
                setTip(0);
              }}
            >
              Tip with cash
            </div>
            <div className="donate_area">
              <span className={selectedPaymantMethod === 1 ? "active" : ""}>
                Custom Tip
              </span>
              <input
                value={customTip}
                className="donate_cash_input"
                type="text"
                step={0.5}
                disabled={selectedPaymantMethod === 0}
                min={0}
                onChange={(event) => {
                  if (
                    checkNumber(
                      event.target.value[event.target.value.length - 1]
                    )
                  ) {
                    setCustomTip(event.target.value);
                  } else if (event.target.value === "") {
                    setCustomTip("");
                  }
                }}
              />
            </div>
          </div>
        </div>
        <NavigateButtons
          onHandleClickForBack={() => {
            isAuth ? changeStep(1) : changeStep(2);
          }}
          onHandleClick={handleCheckout}
          disabled={disabled}
          textNext={"Place order"}
        />
      </div>
      <OrderSummary tip={tip} donate={donate} />
      <ModalWrapper
        height={window.pageYOffset}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      >
        <DonateModal
          closeModal={() => setShowModal(false)}
          height={window.pageYOffset + 300}
        />
      </ModalWrapper>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isDelivery: state.isDelivery,
  step: state.checkout.checkoutStep,
  donateProps: state.checkout.donate,
  total: state.basket.total,
  subTotal: state.basket.subTotal,
  isAuth: state.user.isAuth,
  location: state.currentLocation.address,
  userInfo: state.checkout.userInfo,
  user: state.user.data,
  scheduleDates: state.checkout.schedule.scheduleDate,
  schedule: state.checkout.schedule.schedule,
});

const mapDispatchToProps = {
  changeStep,
  sendUuidCode,
  showLocalLoading,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingPayment);
