import React from "react";
import Nav from "./LayoutComponetns/Nav.Component";
import Footer from "../container/Footer/Footer";
import { Spin } from 'antd4';
import { connect } from "react-redux";
import Modals from "./ModalComponent/Index.Modal.Component";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

const MainApp = (props) => {
	const { children,
		loading,
		showHeader,
	} = props;
	
	return (
		<>
			<Spin spinning={loading}>
				{showHeader ? <Nav /> : ''}
				{children}
				<Footer />
			</Spin>
			<Modals />
		</>
	);
};

MainApp.propTypes = {
	loading: PropTypes.bool,
	currentLocation: PropTypes.object
};

const mapStateToProps = state => ({
	loading: state.loading,
	currentLocation: state.currentLocation.address,
	showHeader: state.showHeader,
	isAuth: state.user.isAuth,
	
});

export default withRouter(connect(
	mapStateToProps,
)(MainApp));

