import React, {memo, useState} from "react";
import {connect} from "react-redux";
import "../../styles/RegularMenuStyle/regularMenu.scss";
import delivery from "../../img/delivery.svg";
import pickup from "../../img/pickup.svg";
import {updateOrderDeliveryType} from "../../actions/orderActions";
import {OrderDeliveryType} from "../../enums/Order.Status.Enum";
import AddAddress from "../../components/HelpersComponents/Add.Address.Component";
import {clearProductList, clearRestaurantList} from "../../actions/baseActions";

const RegularLocation = memo(
    ({
         onAddressConfirmed = (f) => f,
         updateOrderDeliveryType,
         isDelivery,
         clearProductList,
         clearRestaurantList,
     }) => {
        return (
            <div className="HomeSearchComponent">
                <div className={"getFoodType regular_getFoodType"}>
                    <div>
                        <div>
                            <button
                                className={isDelivery ? "active" : ""}
                                onClick={() => {
                                    clearProductList();
                                    clearRestaurantList();
                                    updateOrderDeliveryType(OrderDeliveryType.IS_DELIVERY);
                                }}
                            >
                                <img src={delivery} alt=""/>
                                Delivery
                            </button>
                            <button
                                className={!isDelivery ? "active" : ""}
                                onClick={() => {
                                    clearProductList();
                                    clearRestaurantList();
                                    sessionStorage.setItem('isDelivery', 'true')
                                    updateOrderDeliveryType(OrderDeliveryType.IS_PICK_UP);
                                }}
                            >
                                <img src={pickup} alt=""/>
                                Pick Up
                            </button>
                        </div>
                    </div>
                    <AddAddress onAddressConfirmed={onAddressConfirmed}/>
                </div>
            </div>
        );
    }
);
const mapStateToProps = (state) => ({
    isDelivery: state.isDelivery,
});

const mapDispatchToProps = {
    updateOrderDeliveryType,
    clearProductList,
    clearRestaurantList,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegularLocation);
