import React from "react";
import getStore from "../store/configureStore";
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import MainApp from "./MainApp";
import MainRouters from "./MainRouters";
import {createBrowserHistory} from "history";
import * as UserService from "../services/User.Service";
import {getAuthData, removeAuthData} from "../services/User.Service";
import {BASKET, FILTER, LOCATION, USER} from "../actions/_actionTypes";
import "antd4/dist/antd.css"
import "../styles/LayoutStyle/layout.scss";
import {hasRole, messageType, showMessage} from "../services/Utilities";
import ServerRoles from "../enums/Server.Sider.Roles.enum";

import FCMInitializer from "./FCMInitializer";
import * as BaseService from "../services/Base.Service";
import Paths from "../services/Paths";
import {closeClientConnection, getClientWssConnection} from "../Wss/Client.WSS.Connection";
import ErrorBoundary from "./LayoutComponetns/ErrorBoundary";
import {signOut} from "../api/User.API";

const store = getStore();
const History = createBrowserHistory();

export class App extends React.Component {
    UNSAFE_componentWillMount() {
        BaseService.getFilterDefaultData(store.getState().isCatering).then(result => {
            if (result) {
                store.dispatch({type: FILTER.UPDATE_DEFAULT_DATA, payload: result});
            }
        });

        // authentication information
        const AuthDate = getAuthData();
        if (AuthDate?.id) {
            if ("expiresIn" in AuthDate) delete AuthDate.expiresIn;
            if ("refreshToken" in AuthDate) delete AuthDate.refreshToken;
            if ("token" in AuthDate) delete AuthDate.token;
            if ("roles" in AuthDate) AuthDate.roles = AuthDate.roles.split(",");
            store.dispatch({type: USER.LOGGED_IN, payload: AuthDate});

            UserService.getCurrentUser().then(result => {
                if (result) {
                    store.dispatch({type: USER.UPDATE_PERSONAL_INFORMATION, payload: result});
                    this.initConnections(result);
                    FCMInitializer.getToken();
                } else {
                    this.removeUserDataOnError()
                }
            }).catch(() => {
                this.removeUserDataOnError()
            });
        }

        document.addEventListener("successLoggedIn", this.successLoggedIn);
        document.addEventListener("successLogOut", this.successLogOut);
    }

    componentWillUnmount() {
        document.removeEventListener("successLoggedIn", this.successLoggedIn);
        document.removeEventListener("successLogOut", this.successLogOut);
        this.successLogOut();
    }

    successLogOut = () => {
        signOut()
        FCMInitializer.deleteToken();
        closeClientConnection();
        History.replace(Paths.home, null)
    };

    removeUserDataOnError = () => {
        showMessage(messageType.error, 'There was an error. Please try again.');
        store.dispatch({type: BASKET.CLEAR_CART});
        store.dispatch({type: LOCATION.REMOVE_USER_LOCATION});
        removeAuthData();

        this.successLogOut();
    };

    successLoggedIn = event => {
        FCMInitializer.getToken();
        this.initConnections(event.detail);
    };

    initConnections = user => {
        try {
            if (hasRole(user, [ServerRoles.ROLE_CLIENT])) {
                getClientWssConnection(user.id)
            }
        } catch (e) {
            return e
        }
    };

    componentDidMount() {
        this.lastPath = History.location.pathname;
        window.scrollTo(0, 0);
        //TODO check isAddressSet work
        History.listen(location => {
            if (store.getState().currentLocation.isAddressSet) {
                store.dispatch({
                    type: LOCATION.IS_ADDRESS_SET,
                    payload: false
                });
            }
            if (this.lastPath !== location.pathname) {
                window.scrollTo(0, 0);
            }
            this.lastPath = location.pathname;
        });
    }

    render() {
        return (
            <Provider store={store}>
                <Router history={History}>
                    <MainApp>
                        <ErrorBoundary>
                            <MainRouters/>
                        </ErrorBoundary>
                    </MainApp>
                </Router>
            </Provider>
        );
    }
}

export default App;
