import React, { useEffect } from 'react';
import "../../../styles/Dashboard/Dashboard.scss"
import { useState } from "react";
import { getDietFriendlies, getKitchenTypes, getRestaurantTypes, setPreferencies } from '../../../api/RestaurantTypeAPI';
import { showLocalLoading } from "./../../../actions/baseActions";
import { connect } from "react-redux";

const MyPreferences = ({ showLocalLoading }) => {
    const [restaurantTypes, setRestaurantTypes] = useState([]);
    const [kitchenTypes, setKitchenTypes] = useState([]);
    const [dietFriendly, setDietFriendly] = useState([]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    }, []);
    useEffect(() => {
        const Types = JSON.parse(localStorage.getItem("Types"));
        showLocalLoading(true)
        getRestaurantTypes().then(({ data }) => {
            let newData = data.restaurantTypes.map(element => {
                let isSelected = Types?.categories?.filter(i => i.replace("-", "").toUpperCase() === element.name.replace(" ", "").toUpperCase())[0]
                return {
                    ...element,
                    isSelected: !!isSelected
                }
            });
            setRestaurantTypes(newData)
        });
        getKitchenTypes().then(({ data }) => {
            let newData = data.kitchenTypes.map(element => {
                let isSelected = Types?.kitchenType?.filter(i => i.replace("-", "").toUpperCase() === element.name.replace(" ", "").toUpperCase())[0]
                return {
                    ...element,
                    isSelected: !!isSelected
                }
            });
            setKitchenTypes(newData)
        });
        getDietFriendlies().then(({ data }) => {
            let newData = data.dietFriendlies.map(element => {
                let isSelected = Types.dietFriendly.filter(i => i.replace("-", "").toUpperCase() === element.name.replace(" ", "").toUpperCase())[0]
                return {
                    ...element,
                    isSelected: !!isSelected
                }
            });
            setDietFriendly(newData)
        });
        showLocalLoading(false)
    }, []);
    useEffect(() => {
        if (restaurantTypes && kitchenTypes && dietFriendly) {
            showLocalLoading(false)
        }
    }, [restaurantTypes, kitchenTypes, dietFriendly]);

    const onHandleChange = (key, type) => {
        if (type === "restaurantTypes") {
            let newData = [...restaurantTypes];
            newData[key].isSelected = !newData[key].isSelected;
            setRestaurantTypes(newData)
        }
        if (type === "kitchenTypes") {
            let newData = [...kitchenTypes];
            newData[key].isSelected = !newData[key].isSelected;
            setKitchenTypes(newData)
        }
        if (type === "dietFriendly") {
            let newData = [...dietFriendly];
            newData[key].isSelected = !newData[key].isSelected;
            setDietFriendly(newData)
        }
    }
    const handleChange = () => {
        showLocalLoading(true)
        let sendData = {
            kitchenType: [],
            dietFriendly: [],
            categories: []
        }
        kitchenTypes.forEach(type => {
            if (type.isSelected) {
                sendData.kitchenType.push(type.slug)
            }
        })
        dietFriendly.forEach(type => {
            if (type.isSelected) {
                sendData.dietFriendly.push(type.slug)
            }
        })
        restaurantTypes.forEach(type => {
            if (type.isSelected) {
                sendData.categories.push(type.slug)
            }
        })
        setPreferencies(sendData).then(() => {
            localStorage.setItem("Types", JSON.stringify(sendData))
            showLocalLoading(false)
        })

    }
    return <div className='my_preferences_wrapper'>
        {!!restaurantTypes.length && <><div className='my_preferences_header'>Categories</div>
            <div className='preferences_item'>
                {restaurantTypes.map((item, key) => {
                    return <span className={item.isSelected ? "preferences_item active" : "preferences_item "} onClick={() => {
                        onHandleChange(key, "restaurantTypes")
                    }}>
                        {item.name}
                    </span>
                })}
            </div></>}
        {!!kitchenTypes.length && <> <div className='my_preferences_header'>Kitchen type</div>
            <div className='preferences_item'>
                {kitchenTypes.map((item, key) => {
                    return <span className={item.isSelected ? "preferences_item active" : "preferences_item "} onClick={() => {
                        onHandleChange(key, "kitchenTypes")
                    }}>
                        {item.name}
                    </span>
                })}

            </div></>}
        {!!dietFriendly.length && <><div className='my_preferences_header'>Diet Friendly</div>
            <div className='preferences_item'>
                {dietFriendly.map((item, key) => {
                    return <span className={item.isSelected ? "preferences_item active" : "preferences_item "} onClick={() => {
                        onHandleChange(key, "dietFriendly")
                    }}>
                        {item.name}
                    </span>
                })}
            </div>
            <div className='button_wrapper'>
                <button className={"active_button"} onClick={handleChange}>Save</button>
            </div></>}
    </div >
}
const mapDispatchToProps = {
    showLocalLoading
};

export default connect(
    null,
    mapDispatchToProps
)(MyPreferences);