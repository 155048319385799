import React, { memo, useState } from 'react';
import RestaurantProductItem from './RestaurantProductItem';
import { RestaurantTitle } from './RestaurantTitle';

const RestaurantsProducts = memo(({ item }) => {
    const { name, products=[], id } = item;

    const [showItem, setShowItem] = useState(4)
    return <div className='products_list_wrapper' key={id}>
        <RestaurantTitle name={name} />
        <div className='products_list_item_wrapper'>
            <div className='products_list_category'>
                {!!products.length && products.map((item, i) => {
                    return i < showItem && <RestaurantProductItem key={item.id} item={item} />
                })}
            </div>
        </div>
        {products.length > 4
            ?
            showItem > 4 ?
                <button className='show' onClick={() => {
                    return setShowItem(4)
                }}>Show Less</button>
                :
                <button
                    className='show'
                    onClick={() => {
                        return setShowItem(products.length)

                    }}
                >Show More</button>
            :
            null

        }

    </div>
})

export default RestaurantsProducts