import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addFirstItem } from "../../actions/basketActions";
import "../../styles/ShoppingCart/ShoppingCart.scss";
import arrowMenu from "../../img/arrowMenu.png";
import ShoppingCartItem from "./ShoppingCartItem";
import ProductItem from "../RegularMenuComponent/Product/ProductItem";
import { changeStep } from "../../actions/checkoutAction";
import { getProducts, showModalRegularMenu, updateSelectedRestaurant } from "../../actions/baseActions";
import PopUP from "../RegularMenuComponent/PopUP";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import RestaurantCart from "./RestaurantCart";

const ShoppingCart = ({
    isAuth,
    basket,
    addFirstItem,
    updateSelectedRestaurant,
    changeStep,
    showModalRegularMenu,
    showModal,
    products,
    getProducts,
    width,
}) => {
    const [productCount, setProductCount] = useState(2);

    useEffect(() => {
        addFirstItem().then((data) => {
            if (data?.items?.length) {
                updateSelectedRestaurant(data?.restaurant?.slug, isAuth);
                getProducts(1, 20, { restaurantSlug: data?.restaurant?.slug });
            }
        });
    }, []);

    const { items, subTotal, deliveryFee, tax } = basket;
    const [filterItems, setFilterItems] = useState([]);

    useEffect(() => {
        if (items) setFilterItems([...items]);
    }, [items]);

    return showModal && (
        <>
            <ModalWrapper
                height={window.pageYOffset - 170}
                showModal={showModal}
                closeModal={showModalRegularMenu}
            >
                <PopUP height={window.pageYOffset + 250} />
            </ModalWrapper>
            <div className="shopping_cart_wrapper">
                <div className="shopping_cart_name">Cart</div>
                {items ? (
                    <div className="sc_section_wrapper">
                        <div>
                            <div className="sc_table">
                                <div className="sc_table_header">
                                    <span>Product</span>
                                    <span>Quantity</span>
                                    <span>Total</span>
                                </div>
                                {filterItems?.map((i, j) => <ShoppingCartItem state={filterItems}
                                    setState={setFilterItems} key={j}
                                    item={i} />)}
                                <div className="sc_table_footer">
                                    Subtotal: ${(Math.round(subTotal * 100) / 100).toFixed(2)}
                                </div>
                            </div>
                            <div className="sc_more_food">
                                {!!products?.length && (
                                    <div className="sc_more_food_wrapper">
                                        <span>You may also like</span>
                                        <span className="sc_arrow_wrapper">
                                            <span
                                                className="arrow"
                                                onClick={() => {
                                                    if (productCount > 1) {
                                                        setProductCount(productCount - 1);
                                                    }
                                                }}
                                            >
                                                <img src={arrowMenu} alt={"arrowMenu"} />
                                            </span>
                                            <span
                                                className="arrow"
                                                onClick={() => {
                                                    if (products.length > productCount + 1) {
                                                        setProductCount(productCount + 1);
                                                    }
                                                }}
                                            >
                                                <img
                                                    src={arrowMenu}
                                                    alt={"arrowMenu"}
                                                    style={{ transform: "rotate(180deg)" }}
                                                />
                                            </span>
                                        </span>
                                    </div>
                                )}
                                <div className="itemsWrapper you_may_also_like">
                                    {products?.map((item, key) => {
                                        if (
                                            width < 950 &&
                                            (key - productCount === 0 || key - productCount === -1)
                                        ) {
                                            return <ProductItem item={item} key={key} />;
                                        }
                                        if (
                                            width > 950 &&
                                            (key - productCount === 1 ||
                                                key - productCount === -1 ||
                                                key - productCount === 0)
                                        ) {
                                            return <ProductItem item={item} key={key} />;
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="right_menu_wrapper">
                            <RestaurantCart />
                            <div className="sc_order_summary">
                                <div>Order Summary</div>
                                <span>
                                    {items.length} Items
                                    <span> ${(Math.round(subTotal * 100) / 100).toFixed(2)}</span>
                                </span>
                                <span>
                                    Tax
                                    <span>
                                        {tax === 0
                                            ? "$0.00"
                                            : `$${(Math.round(tax * 100) / 100).toFixed(2)}`}
                                    </span>
                                </span>
                                <span>
                                    Delivery fee
                                    <span>
                                        {deliveryFee === 0
                                            ? "$0.00"
                                            : `$${(Math.round(deliveryFee * 100) / 100).toFixed(2)}`}
                                    </span>
                                </span>
                                <span>
                                    Total{" "}
                                    <span>
                                        $
                                        {(
                                            Math.round((subTotal + deliveryFee + tax) * 100) / 100
                                        ).toFixed(2)}
                                    </span>
                                </span>
                                <button
                                    onClick={() => {
                                        if (items.length) {
                                            changeStep(2);
                                        }
                                    }}
                                >
                                    Checkout
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    basket: state.basket,
    basketModalVisible: state.modalVisible.basket,
    isDelivery: state.isDelivery,
    isAuth: state.user.isAuth,
    isCatering: state.isCatering,
    showModal: state.regularMenu.showModal,
    products: state.products.items,
    location: state.currentLocation.address,
    restaurant: state.selectedRestaurant.restaurant,
    width: state.width,
});

const mapDispatchToProps = {
    addFirstItem,
    changeStep,
    showModalRegularMenu,
    getProducts,
    updateSelectedRestaurant,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
