// Import statements
import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { clearBasket, getBasket } from '../../api/Basket.API';
import { ORDER_DELIVERY_TYPE } from '../../actions/_actionTypes';
import s from './style.module.scss';

export function AddressChangeModal({ setState, isDelivery }) {
    const history = useHistory();
    const dispatch = useDispatch();

    const goHome = () => {
        clearBasket()
            .then(() => getBasket())
            .then(() => {
                document.dispatchEvent(new CustomEvent('updateOrderDeliveryType', {
                    detail: 'IS_DELIVERY',
                }));
                dispatch({
                    type: ORDER_DELIVERY_TYPE.UPDATE_ORDER_DELIVERY_TYPE,
                    payload: 'IS_DELIVERY',
                });
                history.push('/');
            });
    };

    const changeDeliveryType = () => {
        document.dispatchEvent(new CustomEvent('updateOrderDeliveryType', {
            detail: 'IS_PICK_UP',
        }));
        dispatch({
            type: ORDER_DELIVERY_TYPE.UPDATE_ORDER_DELIVERY_TYPE,
            payload: 'IS_PICK_UP',
        });
        setState(false);
    };

    return (
        <div className={s.modal} onContextMenu={(e) => e.preventDefault()}>
            <div>
                <h2>
                    Would you like to choose another restaurant or pick up this order instead? Caution: by clicking to Another Restaurant your cart is about to be emptied.
                </h2>
                <span>
                    <button onClick={goHome}>Another Restaurant</button>
                    <button onClick={changeDeliveryType}>I'll pick up my order</button>
                </span>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isDelivery: state.isDelivery,
});

export default connect(mapStateToProps)(AddressChangeModal);
