import React from 'react';
import { connect } from "react-redux";
import "../../styles/ShippingPayment/ShippingPayment.scss";
import { useHistory } from "react-router-dom";
const ErrorPage = ({ user }) => {
    const history = useHistory()
    return <>
        <div className='ordered_page_wrapper' >
            <div>
                <p>Payment error !</p>
                <span>Please check your email. We have sent a confirmation to your address<br />{user.email}</span>
                <button onClick={()=>history.push("/")}>Go To Menu</button>
            </div>
        </div>
    </>
}
const mapStateToProps = (state) => ({
    user: state.user.data,
   
});



export default connect(
    mapStateToProps,
    null,
)(ErrorPage);
