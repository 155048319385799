import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import PropTypes from "prop-types";
import logo from "../../img/Logo.svg";
import cart from "../../img/ic_kart.svg";
import { showLeftMenu, showModal } from "../../actions/baseActions";
import { addFirstItem } from "../../actions/basketActions";
import { resetFilter } from "../../actions/filterActions";
import ModalTypes from "../../enums/Modal.Types.Enum";
import NavSignIn from "./PartialsComponents/Nav.SignIn.Component";
import { logoutUser } from "../../actions/userActions";
import Scroll from "../../services/Scroll";
import Phone from "../../img/phone.svg";
import meameal from "../../img/meameal.png";
import close from "../../img/close.png";
import { getBasket } from "../../api/Basket.API";
import useWindowDimensions from "../../Utilitis/Utilitis";

const Nav = ({
  addFirstItem,
  basketCount,
  isAuth,
  showModal,
  filterModal,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useState({
    className: "",
    menuMobile: false,
  });
  const [showLeftMenu, setShowLeftMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { width } = useWindowDimensions();
  const ref = useRef(null);

  useEffect(() => {
    getBasket().then((data) => {
      addFirstItem(data.data);
    });
  }, [basketCount, isAuth, addFirstItem, location.pathname]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (width > 1200) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [width]);

  const handleClickOutside = (event) => {
    if (ref?.current && !ref.current.contains(event.target)) {
      setShowLeftMenu(false);
    }
  };

  const handleScroll = () => {
    let scrollTop = window.scrollY / 100;

    if (scrollTop >= 1 && state.className !== "scrolled") {
      setState({
        ...state,
        backgroundColor: "rgba(255, 255, 255, 1)",
        className: "scrolled",
      });
    } else if (scrollTop < 1) {
      setState({
        ...state,
        backgroundColor: `rgba(255, 255, 255, ${scrollTop})`,
        className: "",
      });
    }
  };

  const scrollToBottom = async () => {
    await history.push("");
    await window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
    });
  };

  const scrollToCustumers = async () => {
    await history.push("");
    await window.scrollTo({
      top: 2250,
      behavior: "auto",
    });
  };

  const scrollToForRestaurant = async () => {
    await history.push("");
    await window.scrollTo({
      top: 600,
      behavior: "auto",
    });
  };

  const scrollToForCustumers = async () => {
    await history.push("");
    await window.scrollTo({
      top: 1200,
      behavior: "auto",
    });
  };

  const { className } = state;

  return (
    <nav
      className={`Nav ${className} `}
      style={
        filterModal
          ? { display: "none" }
          : { backgroundColor: "rgb(255 255 255)" }
      }
    >
      <div className={"MobileMenu"} style={!(width < 1200) ? { display: "none" } : { display: "block" }}
        onClick={() => {
          if (isMobile) {
            setShowLeftMenu(true);
          }
        }}
      >
        <span />
      </div>
      <div className={"Logo"}>
        <img
          src={logo}
          alt="logo"
          onClick={() => {
            if (width < 800) {
              showModal(ModalTypes.SIGN_IN_MODAL, false);
              showModal(ModalTypes.CHANGE_PASSWORD_MODAL, false);
              showModal(ModalTypes.FORGOT_PASSWORD_MODAL, false);
              showModal(ModalTypes.REGISTRATION_MODAL, false);
            }
            if (location.pathname === "/") {
              Scroll.scrollToStartOfPage();
            } else {
              history.push("");
            }
          }}
        />
      </div>
      {width > 1200 && (
        <ul className={"Menu"} ref={ref}>
          <li onClick={scrollToForCustumers}>For companies</li>
          <li onClick={scrollToForRestaurant}>For restaurants</li>
          <li onClick={scrollToCustumers}>About us</li>
          <li onClick={scrollToBottom}>Contact us</li>
          <li className="phone_number">
            <img src={Phone} alt="Phone" />
            <a href="tel: +1 323 632 6325">+1 323 632 6325</a>
          </li>
        </ul>
      )}
      <ul className={"Menu sign_menu"}>
        <li
          className={
            !basketCount
              ? "CartIcon d-xl-block hoverable"
              : "CartIcon d-xl-block hoverable NotCount"
          }
          data-count={basketCount}
          onClick={() => showModal(ModalTypes.BASKET_MODAL)}
        >
          <img src={cart} alt="cart" />
        </li>
        {!isMobile && <NavSignIn />}
        {isMobile && isAuth && <NavSignIn />}
      </ul>
      {showLeftMenu && isMobile && (
        <div className="mobile_uList" ref={ref}>
          <div>
            <div className="mobile_list_header">
              <img
                alt="meameal"
                src={meameal}
                onClick={() => {
                  if (width < 800) {
                    showModal(ModalTypes.SIGN_IN_MODAL, false);
                    showModal(ModalTypes.CHANGE_PASSWORD_MODAL, false);
                    showModal(ModalTypes.FORGOT_PASSWORD_MODAL, false);
                    showModal(ModalTypes.REGISTRATION_MODAL, false);
                  }
                }}
              />
              <img
                alt="close"
                src={close}
                onClick={() => {
                  setShowLeftMenu(false);
                }}
              />
            </div>
            <ul className={"mobile_ul_list_items"}>
              <li onClick={scrollToForCustumers}>For companies</li>
              <li onClick={scrollToForRestaurant}>For restaurants</li>
              <li onClick={scrollToCustumers}>About us</li>
              <li onClick={scrollToBottom}>Contact us</li>
              <li className="phone_number">
                <img src={Phone} alt="Phone" />
                <a href="tel: +1 323 632 6325">+1 323 632 6325</a>
              </li>
            </ul>
          </div>
          {!isAuth && (
            <div className="sign_menu">
              <button
                className="login_button"
                onClick={() => {
                  showModal(ModalTypes.CHANGE_PASSWORD_MODAL, false);
                  showModal(ModalTypes.FORGOT_PASSWORD_MODAL, false);
                  showModal(ModalTypes.REGISTRATION_MODAL, false);
                  showModal(ModalTypes.SIGN_IN_MODAL);
                  setShowLeftMenu(false);
                }}
              >
                Log in
              </button>
              <button
                className="sigh_up_button"
                onClick={() => {
                  showModal(ModalTypes.SIGN_IN_MODAL, false);
                  showModal(ModalTypes.CHANGE_PASSWORD_MODAL, false);
                  showModal(ModalTypes.FORGOT_PASSWORD_MODAL, false);
                  showModal(ModalTypes.REGISTRATION_MODAL);
                  setShowLeftMenu(false);
                }}
              >
                Sign up
              </button>
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

Nav.propTypes = {
  showModal: PropTypes.func.isRequired,
  basketCount: PropTypes.number,
  leftMenuVisible: PropTypes.bool.isRequired,
  isAddressSet: PropTypes.bool.isRequired,
  currentLocation: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  basketCount: state.basket.productsCount,
  leftMenuVisible: state.leftMenuVisible,
  currentLocation: state.currentLocation.address,
  isAuth: state.user.isAuth,
  user: state.user.data,
  isAddressSet: state.currentLocation.isAddressSet,
  isCatering: state.isCatering,
  filterModal: state.filterModal,
});

const mapDispatchToProps = {
  showModal,
  showLeftMenu,
  resetFilter,
  logoutUser,
  addFirstItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
