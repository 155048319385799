import React, {useEffect, useState} from 'react';
import {connect} from "react-redux"
import {OrderDeliveryType} from '../../enums/Order.Status.Enum';
import {updateOrderDeliveryType} from "../../actions/orderActions";
import Config from "Config";
import {AddressChangeModal} from "../AddressChangeModal/AddressChangeModal";
import {useLocation} from "react-router";

const DeliveryOption = ({updateOrderDeliveryType, isDelivery}) => {
    const location = useLocation()
    const BaseUrl = Config.ServerUrl;
    const [changeModal, setChangeModal] = useState(false)
    const changeToDelivery = () => {
        fetch(BaseUrl + `/api/v1/restaurant/details/${localStorage.getItem('restaurantSlug')}`, {
            headers: {
                UserLocation: JSON.parse(localStorage.getItem('LocationData'))?.lat + '|' + JSON.parse(localStorage.getItem('LocationData'))?.log
            }
        })
            .then(data => data.json())
            .then(data => !data.success ? setChangeModal(true) : updateOrderDeliveryType(OrderDeliveryType.IS_DELIVERY))
            .catch(_ => setChangeModal(true))
    }
    useEffect(() => {
        fetch(BaseUrl + `/api/v1/restaurant/details/${localStorage.getItem('restaurantSlug')}`, {
            headers: {
                UserLocation: JSON.parse(localStorage.getItem('LocationData'))?.lat + '|' + JSON.parse(localStorage.getItem('LocationData'))?.log
            }
        })
            .then(data => data.json())
            .then(data => setChangeModal(!data.success))
            .catch(err => console.error(err))
    }, [location.pathname])
    useEffect(() => {
        window.scrollTo(0,0)
        document.querySelector('body').style = `height: ${changeModal ? '100vh' : 'auto'}; overflow: ${changeModal ? 'hidden' : 'auto'}`
    }, [changeModal])
    return (
        <>
            {changeModal && <AddressChangeModal setState={setChangeModal}/>}
            <div className='sp_option'>
                <p className='sp_option_header'>Delivery option</p>
                <div>
                    <button
                        className={isDelivery ? 'active' : ''}
                        onClick={changeToDelivery}>
                        Delivery
                    </button>
                    <button
                        className={!isDelivery ? 'active' : ''}
                        onClick={() => updateOrderDeliveryType(OrderDeliveryType.IS_PICK_UP)}>
                        Pick Up
                    </button>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    isDelivery: state.isDelivery,
});

const mapDispatchToProps = {
    updateOrderDeliveryType
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeliveryOption);
