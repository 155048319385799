import * as firebase from "firebase/app";
import "firebase/messaging";
import FirebaseConfig from 'FirebaseConfig';
import {sendFBTokenToServer} from "../services/User.Service";
import getStore from "../store/configureStore";
import {NOTIFICATION} from "../actions/_actionTypes";


const store = getStore();

class FCMInitializer {
		initializeApp() {
				console.info(' ---- Initialize App');
				const context = {
						messagingSenderId: FirebaseConfig.messagingSenderId
				};
				
				return firebase.initializeApp(context);
		}
		
		requestPermission() {
				console.info(' ---- Request Permission');
				return this.getMessaging().requestPermission();
		}
		
		getToken() {
				console.info(' ---- Get Token');
				return this.requestPermission()
						.then(() => (this.getMessaging().getToken()))
						.then(currentToken => {
								if (currentToken) {
										if (!this.isTokenSentToServer(currentToken)) {
												this.sendTokenToServer(currentToken);
										} else {
												console.log(' ---- FB token still the same.')
										}
										this.initializeEventListener();
								} else {
										this.setFBTokenData(false);
								}
								return currentToken;
						})
						.catch(error => {
								if (error.code === "messaging/permission-blocked") {
										console.log("Please Unblock Notification Request Manually");
								} else {
										console.log("Error Occurred", error);
								}
						});
		}
		
		deleteToken() {
				console.info(' ---- Delete Token');
				this.getMessaging()
						.getToken()
						.then(currentToken => {
								this.getMessaging().deleteToken(currentToken)
										.then(() => {
												// TODO Delete from Server then->...
												this.setFBTokenData(false);
										})
										.catch(error => {
												console.log(' ---- Unable to delete token', error);
										});
						})
						.catch(error => {
								console.log('Error retrieving Instance ID token', error);
						});
		}
		
		initializeEventListener() {
				console.info(' ---- Event Listener');
				this.onMessage();
				this.onTokenRefresh();
		}
		
		
		onMessage() {
				this.getMessaging()
						.onMessage(payload => {
								Notification.requestPermission(result => {
										if (result === 'granted' && payload && payload.notification) {
												store.dispatch({
														type: NOTIFICATION.ADD,
														payload: {
																title: payload.notification.title,
																body: payload.notification.body
														}
												})
												
												return navigator.serviceWorker.ready.then(registration => {
														const options = Object.assign({}, {
																body: payload.notification.body,
																icon: payload.notification.icon,
																data: payload.notification
														});
														
														return registration.showNotification(
																payload.notification.title,
																options
														);
												});
												
										}
								});
						});
				
		}
		
		onTokenRefresh() {
				this.getMessaging()
						.onTokenRefresh(() => {
								this.getMessaging()
										.getToken()
										.then(refreshedToken => {
												console.log(' ---- Token refreshed');
												this.sendTokenToServer(refreshedToken);
										})
										.catch(error => {
												console.log('Unable to retrieve refreshed token', error);
										});
						});
		}
		
		sendTokenToServer(currentToken) {
				console.info('---- `Send Token To Server`');
				if (!this.isTokenSentToServer(currentToken)) {
						// sendFBTokenToServer({
						// 	registration_info: {
						// 		registration_id: currentToken,
						// 		mobile_oc: 'web'
						// 	}
						// })
						
						sendFBTokenToServer(currentToken).then(result => {
								if (result && result.success) {
										this.setFBTokenData(currentToken);
								}
						});
						this.setFBTokenData(currentToken);
				} else {
						console.log('Token already sent to server so won\'t send it again unless it changes');
				}
		}
		
		isTokenSentToServer(currentToken) {
				return localStorage.getItem('FCMToken') === currentToken;
		}
		
		setFBTokenData(currentToken) {
			console.log('***************************currentToken');
			console.log(currentToken);
				if (currentToken) {
						console.info('---- Set New Token');
						localStorage.setItem('FCMToken', currentToken);
				} else {
						localStorage.removeItem('FCMToken');
				}
		}
		
		getMessaging() {
				return this.messaging ? this.messaging : (this.messaging = firebase.messaging());
		}
}

export default new FCMInitializer();
