import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { showFilterModal, updateFilter } from "../../../actions/filterActions";
import "../../../styles/RestaurantList/RestaurantList.scss";
import RestaurantItem from "./RestaurantItem";
import arrow from "../../../img/filter_arrow.png";
import v from "../../../img/v.png";
import filterPng from "../../../img/filter.png";
import { addRestaurantPageCount } from "../../../actions/pageCountAction";
import { filterList } from "../../../enums/Product.Enum";
import { clearRestaurantList, getRestaurants } from "../../../actions/baseActions";

const RestaurantList = memo(
    ({
        updateFilter,
        getRestaurants,
        filter,
        restaurants,
        pageCountRestaurant,
        addRestaurantPageCount,
        address,
        isDelivery,
        isAuth,
        width,
        setLocalIsFavorite,
        localIsFavorite,
        showFilterModal,
        clearRestaurantList,
    }) => {
        const [isOpen, setIsOpen] = useState(false);
        const [filterBy, setFilterBy] = useState("");

        useEffect(() => {
            let resFilter = { ...filter };
            delete resFilter.isFiltered;
            delete resFilter.priceMin;
            delete resFilter.priceMax;
            delete resFilter.distanceMax;
            delete resFilter.selectedCategories;
            delete resFilter.cal;
            delete resFilter.deliveryTime;
            if (!resFilter.isOpenNow) {
                delete resFilter.isOpenNow;
            }
            if (!address && isDelivery) {
                clearRestaurantList();
            } else if (!isDelivery) {
                getRestaurants(
                    pageCountRestaurant,
                    6,
                    { ...resFilter },
                    isAuth,
                    isDelivery
                );
            } else if (address && isDelivery) {
                getRestaurants(
                    pageCountRestaurant,
                    6,
                    { ...resFilter },
                    isAuth,
                    isDelivery
                );
            }
        }, [
            filter.orderBy,
            filter.dir,
            filter.searchText,
            filter.kitchenTypes,
            filter.dietFriendly,
            JSON.stringify(filter.isOpenNow),
            pageCountRestaurant,
            address,
            isDelivery,
        ]);
        useEffect(() => {
            return () => {
                clearRestaurantList();
            };
        }, [isDelivery]);
        const filterBySelect = (filter) => {
            if (filter === "fromAtoZ") {
                updateFilter({
                    orderBy: "name",
                    dir: "ASC",
                });
            }
            if (filter === "fromZtoA") {
                updateFilter({
                    orderBy: "name",
                    dir: "DESC",
                });
            }

            if (filter === "byRating") {
                updateFilter({
                    orderBy: "rating",
                    dir: "DESC",
                });
            }
            if (filter === "HigherPrice") {
                updateFilter({
                    orderBy: "price",
                    dir: "DESC",
                });
            }
            if (filter === "LowerPrice") {
                updateFilter({
                    orderBy: "price",
                    dir: "ASC",
                });
            }
            if (filter === "nearestToMe") {
                updateFilter({
                    orderBy: "distance",
                    dir: "ASC",
                });
            }
        };
        const sort = (
            <div className="filter_list_wrapper">
                <span onClick={() => setIsOpen(!isOpen)}>
                    Sort by{" "}
                    <img src={arrow} alt="arrow" className={isOpen ? "active" : ""} />{" "}
                </span>
                <div className="filter_list" style={isOpen ? {} : { display: "none" }}>
                    {filterList.map((i, j) => (
                        <div
                            className="filter_list_item selected"
                            key={j}
                            onClick={() => {
                                setFilterBy(i.value);
                                filterBySelect(i.value);
                                setIsOpen(false);
                            }}
                        >
                            <div>{i.name}</div>
                            {" "}
                            {filterBy === i.value ? <img src={v} alt={v} /> : null}
                        </div>
                    ))}
                </div>
            </div>
        );
        return restaurants?.items?.length ? (
            <div className="restaurant_list_wrapper">
                {width < 650 && (
                    <div className="restaurant_list_header">
                        <div className="filter_for_mobile">
                            <span onClick={showFilterModal}>
                                {" "}
                                <img src={filterPng} alt="Filter" />
                                Filter
                            </span>
                            {sort}
                        </div>
                    </div>
                )}
                <div className="restaurant_list_header">
                    <span>Restaurants</span>
                    {width > 650 && sort}
                </div>
                <div className="restaurant_items_Wrapper">
                    {restaurants?.items?.map((item, k) => (
                        <RestaurantItem key={k} item={item} setLocalIsFavorite={setLocalIsFavorite} localIsFavorite={localIsFavorite} />
                    ))}
                </div>
                {restaurants?.total > restaurants?.items?.length && (
                    <div className="show_more_button">
                        <button onClick={addRestaurantPageCount}>
                            View More
                        </button>
                    </div>
                )}
            </div>
        ) : null;
    }
);
const mapStateToProps = (state) => ({
    restaurants: state.restaurants,
    filter: state.filter,
    pageCountRestaurant: state.pageCountRestaurant,
    address: state.currentLocation.address.address,
    isDelivery: state.isDelivery,
    isAuth: state.user.isAuth,
    width: state.width,
});

const mapDispatchToProps = {
    updateFilter,
    getRestaurants,
    addRestaurantPageCount,
    showFilterModal,
    clearRestaurantList,
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantList);
