import React from "react";
import Config from "Config";
import Autocomplete from "react-google-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
import { CloseOutlined } from "@ant-design/icons";

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elementWidth: null,
    };
    this.address = React.createRef();
    this.wrapperDivRef = React.createRef();
  }
  componentDidMount() {
    const elementWidth = this.wrapperDivRef.current.offsetWidth;
    this.setState({ elementWidth });
    if (this.props.isDelivery) {
      this.address.current.refs.input.disabled = false;
    } else if (!this.props.isDelivery) {
      this.address.current.refs.input.disabled = true;
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.address.current && "input" in this.address.current.refs) {
      this.address.current.refs.input.value = nextProps.defaultValue
        ? nextProps.defaultValue
        : "";
    }
    if (nextProps.isDelivery) {
      this.address.current.refs.input.disabled = false;
    } else if (!nextProps.isDelivery) {
      this.address.current.refs.input.disabled = true;
      this.address.current.refs.input.value = "";
    }
  }

  handleOnChange = (evt) => {
    if (this.props.getInputState !== undefined) {
      this.props.getInputState(evt.target.value);
    } else if (this?.address?.current?.refs?.input?.value === "") {
      this.props.getInputState("");
    }
  };
  render() {
    return (
      <div
        ref={this.wrapperDivRef}
        className="vehicleInformation"
        style={!this.props.isDelivery ? { opacity: "0.7" } : { opacity: 1 }}
      >
        <Autocomplete
          {...this.props}
          disabled={!this.props.isDelivery}
          onChange={this.handleOnChange}
          loaded={this.props.loaded ? "true" : "false"}
          ref={this.address}
          types={[]}
        />
        {this.props.defaultValue && this.state.elementWidth && (
          <CloseOutlined
            onClick={this.props.deleteLocation}
            style={{
              left: "0",
              top: "50%",
              transform: "translate(130%,-30%)",
              width: "18px",
              height: "18px",
              position: "absolute",
            }}
          />
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: Config.GoogleMapKey,
})(Address);
