import React, {Component} from 'react';
import {GoogleApiWrapper, Map as MapComponent } from 'google-maps-react'
import CustomMarker from './Custom.Marker.Component';
import Config from 'Config';

import marker from '../../../img/brunch-location-icon.png'
import PropTypes from "prop-types";


export const getAddressFromLatLng = (lat, lng) => {
		return new Promise((resolve, reject) => {
			if (window.google) {
				const geocoder = new window.google.maps.Geocoder();
				geocoder.geocode({'latLng': { lat, lng }},
					(results, status) => {
						if (status === 'OK') {
							resolve(results[0])
						} else {
							reject(new Error('Geocode was not successful for the following reason: ' + status))
						}
					})
			} else {
				console.log(new Error('Google was not found'))
			}
		})
}


class Map extends Component {
	Map = undefined;
	constructor(props) {
		super(props);
		
		this.state = {
			center: props.center && props.center.lat !== undefined && props.center.lng !== undefined ? props.center : false,
		}
	}
	
	UNSAFE_componentWillReceiveProps(nextProps) {
		const {center} = nextProps;
		
		if (center && (this.state.center.lat !== center.lat || this.state.center.lng !== center.lng)) {
			this.setState({center: center});
		}
	}
	
	render() {
		const {center} = this.state;
		const {markerDraggable} = this.props;
		
		return (
			<MapComponent
				google={this.props.google}
				initialCenter={center ? center : undefined}
				center={center ? center : undefined}
				onDragend={this.centerMoved}
				onReady={(event, map) => {
					this.Map = map;
				}}
				mapTypeControl={false}
				streetViewControl={false}>
				{center instanceof Object && 'lat' in center && 'lng' in center && (
					<CustomMarker
						icon={marker}
						position={center}
						draggable={markerDraggable}
						onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
					/>
				)}
			</MapComponent>
		);
	}
	
	centerMoved = (mapProps, map) => {
		const {center} = this.state;
		const {mapDraggable} = this.props;
		if (mapDraggable && center && (center.lat !== undefined && center.lng !== undefined)) {
			const position = {
				lat: map.center.lat(),
				lng: map.center.lng()
			};
			this.updateAddress(position);
			return position;
		}
		return false;
	};
	
	onMarkerDragEnd = coord => {
		this.setState({loading: true});
		const position = {
			lat: coord.latLng.lat(),
			lng: coord.latLng.lng()
		};
		this.updateAddress(position);
	};
	
	updateAddress = position => {
		if ('lat' in position && 'lng' in position) {
			getAddressFromLatLng(position.lat, position.lng).then((res) => {
				if (res) {
					this.props.onPlaceSelected(res);
					this.toMapCenter(position);
				}
			})
		}
	};
	
	toMapCenter = position => {
		if ('lat' in position && 'lng' in position) {
			this.setState({
				center: position,
				loading: false
			});
		}
	};
}

Map.propTypes = {
	center: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.object
	]),
	markerDraggable: PropTypes.bool,
	mapDraggable: PropTypes.bool,
	onPlaceSelected: PropTypes.func
};

Map.defaultProps = {
	center: false,
	markerDraggable: false,
	mapDraggable: false,
	onPlaceSelected: f => f
};

export default GoogleApiWrapper({
	apiKey: Config.GoogleMapKey
})(Map)
