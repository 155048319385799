import React from 'react';
import { connect } from "react-redux";
import close from "../../img/close.png"
import { addDonate } from "./../../actions/checkoutAction";
const DonateModal = ({ closeModal, addDonate,height }) => {
    return (
        <div className='donate_wrapper' style={{ top: height }}>
            <div className='donate_header'>
                <span>Donation agrement</span>
                <img onClick={() => {
                    addDonate(false)
                    closeModal()
                }} src={close} alt="close" />
            </div>
            <div className='donate_body'>
                <span>Are you accept   <span>term</span> </span>
                <div>
                    <button onClick={() => {
                        addDonate(false)
                        closeModal()
                        }} className='no'>No</button>
                    <button onClick={() =>{ 
                        addDonate(true)
                        closeModal()
                        }}>Yes</button>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    addDonate
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DonateModal);
