import React, { useEffect } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import Paths from '../services/Paths';
import { connect } from "react-redux";
import { Roles as ServerRoles } from './../enums/Server.Sider.Roles.enum';

// Components
import Preview from './LayoutComponetns/Preview.Component'
import Account from "./AccountComponents/Account.Component";
// import Restaurant from "./RestaurantsComponents/Restaurant.Component";
import Regular from './RegularMenuComponent/RegularMenuComponent';
// import ResetPassword from './SecurityComponents/Ressetting/Reset.Password.Component';
import HomeComponent from "./HomeComponents/Home.Component";
import NoMatch from "./LayoutComponetns/PageNotFound.Component";
// import CateringMenu from './CateringSection/CateringSection';
// import CateringSepRestourantPage from './CateringSection/CateringSepRestourantPage';
import ShippingCart from './ShoppingCart/ShippingCart';
import SelectedRestaurant from "./RegularMenuComponent/Restaurant/SelectedRestaurant";
import PageForGuest from '../container/PageForGuest/PageForGuest';
import useWindowDimensions from '../Utilitis/Utilitis';
import { setInnerWidth } from '../actions/baseActions';
import { scrollHidden } from '../settings/scrollHidden';

const MainRouters = ({ setInnerWidth, filterModal }) => {
	const { height, width } = useWindowDimensions();
	useEffect(() => {
		setInnerWidth(width)
	}, [width]);
	useEffect(() => {
		scrollHidden(filterModal)
	}, [filterModal, width]);

	return (
		<Switch>
			<Route exact path={Paths.home} component={Preview} availableRoles={[ServerRoles.ROLE_CLIENT]} />
			<Route exact path={Paths.cateringSection} component={HomeComponent} availableRoles={[ServerRoles.ROLE_CLIENT]} />

			<Route path={Paths.restaurant} component={SelectedRestaurant} availableRoles={[ServerRoles.ROLE_CLIENT]} />
			<Route path={Paths.regular} component={Regular}
				availableRoles={[ServerRoles.ROLE_USER]}
			/>
			{/* <Route exact path={Paths.restaurant} component={CateringMenu}
				availableRoles={[ServerRoles.ROLE_CLIENT]}
			/> */}
			{/*TODO check signIn*/}
			<Route path={Paths.signIn} component={() => <Redirect to={Paths.home} />} />
			<Route exact path={Paths.shoppingCart} component={ShippingCart} availableRoles={[ServerRoles.ROLE_CLIENT]} />
			{/* <Route exact path={Paths.restaurant} component={Restaurant} availableRoles={[ServerRoles.ROLE_CLIENT]} /> */}
			<Route exact path={Paths.account} component={Account} availableRoles={[ServerRoles.ROLE_CLIENT]} />
			<Route path={Paths.guest} component={PageForGuest} />

			<Route component={NoMatch} />
		</Switch>
	);
};

const mapStateToProps = state => ({
	filterModal: state.filterModal,
});
const mapDispatchToProps = {
	setInnerWidth
};
export default connect(mapStateToProps, mapDispatchToProps)(MainRouters);
