import React, {useEffect, useState} from "react";
import "../../../styles/Dashboard/Dashboard.scss";
import {connect} from "react-redux";
import {getOrderFeedback, reOrder} from "../../../api/Order.API";
import {showLocalLoading} from "../../../actions/baseActions";
import arrowRight from "../../../img/arrow-right.png";
import undraw from "../../../img/undraw.png";
import close from "../../../img/close.png";
import feedback_empty_star from "../../../img/feedback_empty_star.png";
import feedback_star from "../../../img/feedback_star.png";
import {sendCustomerFeedback} from "../../../services/Order.Service";
import {useHistory} from "react-router";
import ModalWrapper from "../../ModalWrapper/ModalWrapper";
import {getOrderHistoryList} from "../../../actions/orderHistoryAction";
import {scrollTop} from "../../../settings/scrollToTop";
import {monthNames} from "../../../enums/Months.Enum";
import {updateOrderDeliveryType} from "../../../actions/orderActions";
import {OrderDeliveryType} from "../../../enums/Order.Status.Enum";

const OrderHistory = ({
                          orderHistory,
                          getOrderHistoryList,
                          updateOrderDeliveryType,
                      }) => {
    const history = useHistory();
    const [orderNumber, setOrderNumber] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [feedbackItemSlug, setFeedbackItemSlug] = useState();
    const [page, setPage] = useState(1);
    const [value, setValue] = useState("");
    const [feedbacks, setFeedbacks] = useState([
        {
            id: "driverRate",
            name: "Delivery",
            stars: [0, 0, 0, 0, 0],
            count: 0,
        },
        {
            id: "serviceRate",
            name: "Service",
            stars: [0, 0, 0, 0, 0],
            count: 0,
        },
        {
            id: "foodRate",
            name: "Food",
            stars: [0, 0, 0, 0, 0],
            count: 0,
        },
    ]);
    useEffect(() => {
        scrollTop();
    }, []);
    useEffect(() => {
        getOrderHistoryList(page);
    }, [page]);
    const setDefaultValue = () => {
        setShowModal(false);
        setValue("");
        setFeedbacks([
            {
                id: "driverRate",
                name: "Delivery",
                stars: [0, 0, 0, 0, 0],
                count: 0,
            },
            {
                id: "serviceRate",
                name: "Service",
                stars: [0, 0, 0, 0, 0],
                count: 0,
            },
            {
                id: "foodRate",
                name: "Food",
                stars: [0, 0, 0, 0, 0],
                count: 0,
            },
        ]);
    };
    const sendFeedback = () => {
        let data = {
            driverRate: feedbacks[0].count,
            serviceRate: feedbacks[1].count,
            foodRate: feedbacks[2].count,
            opinion: value,
        };
        sendCustomerFeedback(data, feedbackItemSlug).then(() => {
            setDefaultValue();
        });
    };
    const getOrderFeedbacks = (data) => {
        let fb = Object.keys(data);
        setValue(data.opinion);
        let newFeedBack = [...feedbacks];
        newFeedBack.forEach((feedback, index) => {
            fb.forEach((fb) => {
                if (feedback.id === fb) {
                    let newStars = [...feedback.stars];
                    newStars.forEach((i, j) => {
                        if (j < data[fb]) {
                            newStars[j] = 1;
                        } else {
                            newStars[j] = 0;
                        }
                    });
                    newFeedBack[index] = {
                        ...feedback,
                        count: data[fb],
                        stars: newStars,
                    };
                }
            });
        });
        setFeedbacks(newFeedBack);
    };
    const feedBackShow = () => {
        return (
            <div
                className="feedback_wrapper"
                style={{top: window.pageYOffset + 350}}
            >
                <div className="feedback_header">
                    <span>Feedback</span>
                    <img alt={"close"} src={close} onClick={() => setShowModal(false)}/>
                </div>
                <div className="feedback_body">
                    {feedbacks.map((i, j) => {
                        return (
                            <div className="stars_wrapper" key={j}>
                                <span className="feedback_types_name">{i.name}</span>
                                {i.stars.map((k, r) => {
                                    return (
                                        <span
                                            className="stars"
                                            key={r}
                                            onClick={() => {
                                                let newFeedbacks = [...feedbacks];
                                                let arr = newFeedbacks[j].stars;
                                                for (let index = 0; index < r + 1; index++) {
                                                    arr[index] = 1;
                                                }
                                                for (let index = arr.length - 1; index > r; index--) {
                                                    arr[index] = 0;
                                                }
                                                newFeedbacks[j] = {
                                                    ...feedbacks[j],
                                                    count: r + 1,
                                                    stars: arr,
                                                };
                                                setFeedbacks(newFeedbacks);
                                            }}
                                        >
                      {k === 1 ? (
                          <img src={feedback_star} alt="star"/>
                      ) : (
                          <img src={feedback_empty_star} alt="star"/>
                      )}
                    </span>
                                    );
                                })}
                            </div>
                        );
                    })}
                    <div className="feedback_input">
                        <h1>Comment</h1>
                        <textarea
                            placeholder="Type here"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </div>
                </div>
                <button
                    disabled={
                        value ||
                        feedbacks[0].count ||
                        feedbacks[1].count ||
                        feedbacks[2].count
                            ? false
                            : true
                    }
                    style={
                        value ||
                        feedbacks[0].count ||
                        feedbacks[1].count ||
                        feedbacks[2].count
                            ? {opacity: 1}
                            : {opacity: "0.5"}
                    }
                    onClick={() => sendFeedback()}
                >
                    Submit
                </button>
            </div>
        );
    };

    return (
        <>
            <div className="order_history_wrapper">
                {orderHistory.length ? (
                    <>
                        <div className="order_history_item order_history_header">
                            <span className="order_history_item_id">Order #</span>
                            <span className="order_history_item_date">Date purchased</span>
                            <span className="order_history_item_status_header">Status</span>
                            <span className="order_history_item_price">Total</span>
                            <span className="order_history_item_price">Items</span>
                            <span className="order_history_item_price">Actions</span>
                            {/* <span>xxx</span> */}
                        </div>
                        {orderHistory.map((i, j) => {
                            let day = new Date(i.created);

                            return (
                                <div
                                    className="item"
                                    style={
                                        i.status === 1
                                            ? {border: "1px solid #ADFF2F"}
                                            : i.status === 0
                                                ? {border: "1px solid #009D06"}
                                                : i.status === 6
                                                    ? {border: "1px solid #B6B6B6"}
                                                    : i.status === 3
                                                        ? {border: "1px solid blue"}
                                                        : {border: "1px solid #0F3460"}
                                    }
                                >
                                    <div key={j} className="order_history_item">
                                        <span className="order_history_item_id">#{i.id}</span>
                                        <span className="order_history_item_date">
                      {monthNames[day.getMonth()]} {day.getDay()},
                                            {day.getFullYear()}
                    </span>
                                        <span
                                            className="order_history_item_status"
                                            style={
                                                i.status === 1
                                                    ? {backgroundColor: "#ADFF2F"}
                                                    : i.status === 0
                                                        ? {backgroundColor: "#009D06"}
                                                        : i.status === 6
                                                            ? {backgroundColor: "#B6B6B6"}
                                                            : i.status === 3
                                                                ? {backgroundColor: "blue"}
                                                                : {backgroundColor: "#0F3460"}
                                            }
                                        >
                      {i.showStatus === "Done"
                          ? "Delivered"
                          : i.showStatus === "Waiting restaurant"
                              ? "Waiting"
                              : i.showStatus}
                    </span>
                                        <span className="order_history_item_price">
                      ${Math.floor(i.totalPrice * 100) / 100}
                    </span>
                                        <span className="order_history_item_price">
                      {i.item.length} items
                    </span>

                                        <span className="order_history_item_feedback">
                      {i.status === 5 ? "Leave feedback" : ""}
                    </span>
                                        <img
                                            className="order_history_item_img"
                                            style={
                                                j === orderNumber
                                                    ? {transform: "rotate(90deg)"}
                                                    : {transform: "rotate(0)"}
                                            }
                                            src={arrowRight}
                                            alt="arrowRight"
                                            onClick={() => {
                                                if (j === orderNumber) {
                                                    setOrderNumber(null);
                                                }
                                                if (j !== orderNumber) {
                                                    setOrderNumber(j);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div
                                        key={day.toString()}
                                        className="more_desc"
                                        style={
                                            j === orderNumber
                                                ? {display: "block"}
                                                : {display: "none"}
                                        }
                                    >
                                        <div className="more_desc_wrapper">
                                            <div className="">
                                                {i.item.map((i, j) => {
                                                    return (
                                                        <div className="more_wrapper" key={j}>
                                                            <img src={i.product.downloadLink} alt={i.name}/>
                                                            <div>
                                <span className="product_name">
                                  {i.product.name}
                                </span>
                                                                <span className="product_price">
                                  {i.cnt} X $ {i.price}
                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="prices_wrapper">
                                                <div>
                                                    <span>Items subtotal</span>
                                                    <span>${i.totalPrice} </span>
                                                </div>
                                                <div>
                                                    {i.deliveryType ? <span>Delivery fee</span> : <span>Pickup</span>}
                                                    {!!i.deliveryType && <span>${i.deliveryPrice}</span>}
                                                </div>
                                                <div>
                                                    <span>Service fee</span>
                                                    <span>${i.servicePrice}</span>
                                                </div>
                                                <div>
                                                    <span>Tip</span>
                                                    <span>${i.tipAmount}</span>
                                                </div>
                                                <div>
                                                    <span>Tax</span>
                                                    <span>${i.tax}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="buttons_wrapper">
                                            {i.status === 5 && (
                                                <button
                                                    onClick={() => {
                                                        setShowModal(true);
                                                        setFeedbackItemSlug(i.slug);
                                                        getOrderFeedback(i.slug).then(({data}) => {
                                                            if (data.feedback) {
                                                                getOrderFeedbacks(data.feedback);
                                                            }
                                                        });
                                                    }}
                                                >
                                                    Feedback
                                                </button>
                                            )}
                                            <button
                                                onClick={() => {
                                                    reOrder(i.slug).then((data) => {
                                                        i.deliveryType === 1
                                                            ? updateOrderDeliveryType(
                                                                OrderDeliveryType.IS_DELIVERY
                                                            )
                                                            : updateOrderDeliveryType(
                                                                OrderDeliveryType.IS_PICK_UP
                                                            );
                                                        if (data.success) history.push("/shopping-cart")
                                                    });
                                                }}
                                            >
                                                Repeat order
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <button
                            className="show_more"
                            onClick={() => {
                                setPage(page + 1);
                            }}
                        >
                            Show More
                        </button>
                    </>
                ) : (
                    <div className="no_order_history">
                        <img src={undraw} alt="undraw"/>
                        <div>No order yet</div>
                    </div>
                )}
                <ModalWrapper
                    height={window.pageYOffset}
                    showModal={showModal}
                    closeModal={() => setDefaultValue()}
                >
                    {feedBackShow()}
                </ModalWrapper>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    localLoading: state.localLoading,
    orderHistory: state.orderHistory,
});

const mapDispatchToProps = {
    showLocalLoading,
    getOrderHistoryList,
    updateOrderDeliveryType,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
