import React from 'react';
import {useEffect, useState} from "react";
import {addNewCreditCard, deleteCreditCard} from "../../../services/CreditCards.Service";
import trash from "../../../img/trash.png"
import cardIcon from "../../../img/cardIcon.png"
import date from "../../../img/date.png"
import close from "../../../img/close.png"
import lock from "../../../img/lock.png"
import userIcon from "../../../img/user.png"
import "../../../styles/Dashboard/Dashboard.scss"
import {getList} from '../../../api/CreditCard.API';
import {cardIcons} from "../../../enums/Card.Icons.Enum";
import {refactorCardDate} from '../../../services/Utilities';
import {connect} from 'react-redux';
import {showLocalLoading} from "../../../actions/baseActions"
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import {useSelector} from "react-redux";

const AccountPayment = ({showLocalLoading, localLoading}) => {
    const width = useSelector((state) => state.width)
    const [showModal, setShowModal] = useState(false)
    const [cardValue, setCardValue] = useState({
        cardType: "",
        cardNumber: "",
        cardDate: "",
        cvv: "",
        holderName: ""
    });
    const [cards, setCards] = useState([])
    const getCards = () => {
        getList()
            .then((data) => setCards(data.data))
            .finally(() => showLocalLoading(false))
    }
    const cardDelete = (id) => {
        if (window.confirm('Sure?')) {
            setCards(state => state.filter(item => item.id !== id))
            deleteCreditCard(id).finally(() => getCards())
        }
    }
    useEffect(() => {
        showLocalLoading(true)
        getCards()
    }, []);

    const addBankCard = () => {
        if (cardValue.cvv.length > 2 &&
            cardValue.holderName.length &&
            cardValue.cardNumber.length > 18 &&
            cardValue.cardDate.length > 4) {
            showLocalLoading(true)
            addNewCreditCard({
                number: cardValue.cardNumber.replace(/ /g, ''),
                expDate: refactorCardDate(cardValue.cardDate),
                isDefault: false,
                cvv: cardValue.cvv,
                zip: "1234",
                holderName: cardValue.holderName
            }).then(d => {
                if (d) {
                    setShowModal(false);
                    setCardValue({
                        cardNumber: "",
                        cardDate: "",
                        cvv: "",
                    })
                } else {
                    return false
                }
            }).finally(getCards)
        }
    }
    const createModalCard = () => {
        let {cardNumber, cardDate, cvv, holderName} = cardValue;
        let card = width > 800 ?
            <div className={'modal_card'}>
                <div className='modal_card_header'>
                    <span>Add a payment method</span>
                    <img src={close} alt="close" onClick={() => setShowModal(false)}/>
                </div>
                <div className='modal_card_body'>
                    <div className='card_number_area'>
                        <span>Holder Name</span>
                        <img src={userIcon} alt="userIcon"/>
                        <input
                            placeholder='Lastname Firstname'
                            value={holderName}
                            onChange={(e) => {
                                setCardValue({...cardValue, holderName: e.target.value})
                            }}
                        />
                    </div>
                    <div className='card_number_area'>
                        <span>Card number</span>
                        <img src={cardIcon} alt="cardIcon"/>
                        <input
                            placeholder='0000 0000 0000 0000'
                            // type="number"
                            value={cardNumber}
                            onKeyDown={e => {
                                if (e.key === "Backspace" && cardNumber.length === 1) {
                                    setCardValue({...cardValue, cardNumber: ""})
                                }
                                if (e.key === "Backspace" && cardNumber.substr(-1) === " ") {
                                    setCardValue({...cardValue, cardNumber: cardNumber.slice(0, cardNumber.length - 1)})
                                }
                            }}
                            onChange={e => {
                                let a = e.target.value;
                                const reg = new RegExp('^[0-9 ]+$');
                                if (!reg.test(a)) {
                                    return false
                                } else {
                                    setCardValue({...cardValue, cardNumber: a})
                                    if (a.length === 4 || a.length === 9 || a.length === 14) {
                                        a = `${a + " "}`;
                                        setCardValue({...cardValue, cardNumber: a})
                                    }
                                }
                            }}
                            maxLength={19}
                        />
                    </div>
                    <div className='div_wrapper'>
                        <div className='card_number_area date'>
                            <span>MM/YY</span>
                            <img src={date} alt="date"/>
                            <input
                                type="text"
                                value={cardDate}
                                maxLength={5}
                                onKeyDown={e => {
                                    if (e.keyCode === 8 && cardDate.substr(-1) === "/") {
                                        setCardValue({...cardValue, cardDate: cardDate.slice(0, 2)})
                                    }
                                }}
                                onChange={e => {
                                    let a = e.target.value.toString()
                                    if (a.match(/[/]*/g) || a.match(/[0-9]*/g)) {
                                        if (a.length > 5) {
                                            return false
                                        }
                                        setCardValue({...cardValue, cardDate: a})
                                        if (a.length === 2) {
                                            a = a + "/"
                                            setCardValue({...cardValue, cardDate: a})
                                        }
                                    }

                                }}
                                placeholder={"09/22"}
                            />
                        </div>
                        <div className='card_number_area cvv'>
                            <span>CVV</span>
                            <img src={lock} alt="lock"/>
                            <input
                                type='number'
                                placeholder='123'
                                maxLength={3}
                                value={cvv}
                                onChange={e => {
                                    if (e.target.value.match("^[0-9]*$")) {
                                        if (e.target.value.length > 3) {
                                            return false
                                        } else {
                                            setCardValue({...cardValue, cvv: e.target.value})
                                        }
                                    }
                                }}/>
                        </div>
                    </div>
                </div>
                <button onClick={addBankCard}>Add</button>
            </div> : <div className='mobile_modal_card'>
                <div className='modal_card_body'>
                    <div className='card_number_area'>
                        <span>Holder Name</span>
                        <img src={userIcon} alt="userIcon"/>
                        <input
                            placeholder='Lastname Firstname'
                            value={holderName}
                            onChange={(e) => setCardValue({...cardValue, holderName: e.target.value})}
                        />
                    </div>
                    <div className='card_number_area'>
                        <span>Card number</span>
                        <img src={cardIcon} alt="cardIcon"/>
                        <input
                            id="myInput"
                            placeholder='0000 0000 0000 0000'
                            value={cardNumber}
                            onKeyDown={e => {
                                if (e.key === "Backspace" && cardNumber.length === 1) {
                                    setCardValue({...cardValue, cardNumber: ""})
                                }
                                if (e.key === "Backspace" && cardNumber.substr(-1) === " ") {
                                    setCardValue({...cardValue, cardNumber: cardNumber.slice(0, cardNumber.length - 1)})
                                }
                            }}
                            onChange={e => {
                                let a = e.target.value;
                                const reg = new RegExp('^[0-9 ]+$');
                                if (!reg.test(a)) {
                                    return false
                                } else {
                                    setCardValue({...cardValue, cardNumber: a})
                                    if (a.length === 4 || a.length === 9 || a.length === 14) {
                                        a = `${a + " "}`;
                                        setCardValue({...cardValue, cardNumber: a})
                                    }
                                }
                            }}
                            maxLength={19}
                        />
                    </div>
                    <div className='div_wrapper'>
                        <div className='card_number_area date'>
                            <span>MM/YY</span>
                            <img src={date} alt="date"/>
                            <input
                                type="text"
                                value={cardDate}
                                maxLength={5}
                                onKeyDown={e => {
                                    if (e.keyCode === 8 && cardDate.substr(-1) === "/") {
                                        setCardValue({...cardValue, cardDate: cardDate.slice(0, 2)})
                                    }
                                }}
                                onChange={e => {
                                    let a = e.target.value.toString()
                                    if (a.match(/[/]*/g) || a.match(/[0-9]*/g)) {
                                        if (a.length > 5) {
                                            return false
                                        }
                                        setCardValue({...cardValue, cardDate: a})
                                        if (a.length === 2) {
                                            a = a + "/"
                                            setCardValue({...cardValue, cardDate: a})
                                        }
                                    }

                                }}
                                placeholder={"09/22"}
                            />
                        </div>
                        <div className='card_number_area cvv'>
                            <span>CVV</span>
                            <img src={lock} alt="lock"/>
                            <input
                                type='number'
                                placeholder='123'
                                maxLength={3}
                                value={cvv}
                                onChange={e => {
                                    if (e.target.value.match("^[0-9]*$")) {
                                        if (e.target.value.length > 3) {
                                            return false
                                        } else {
                                            setCardValue({...cardValue, cvv: e.target.value})
                                        }
                                    }
                                }}/>
                        </div>
                    </div>
                </div>
                <div className='date_desc'>Data is protected under the PCI DSS standard. We do not store your data and
                    do not share it with the merchant.
                </div>

                <div className='button_wrapper'>
                    <button onClick={() => setShowModal(false)}>Cancel</button>
                    <button onClick={addBankCard}>Save</button>
                </div>
            </div>
        return card
    }
    return <>
        {!localLoading && <div className='account_payment_wrapper'>
            {!showModal && <button className='add_new' onClick={() => setShowModal(true)}>Add New</button>}
            {showModal && width < 800 && createModalCard()}
            <ul>
                {
                    cards.map((card) => {
                        const {cardType, expDate, showNumber, id} = card;
                        return <li key={id}>
                            <div style={{width: "140px"}}>
                                <img src={cardIcons['unknown']} className="card_icon" alt="card"/><span
                                className='card_type'>{cardType}</span>
                            </div>
                            <div>{showNumber}</div>
                            <div>{expDate}</div>
                            <div className='accaunt_payment_icon'>
                                <img alt="trash" src={trash} onClick={() => cardDelete(id)}/>
                            </div>
                        </li>
                    })
                }
            </ul>
            {width > 800
                &&
                <ModalWrapper
                    closeModal={() => setShowModal(false)}
                    showModal={showModal}
                >
                    {createModalCard()}
                </ModalWrapper>
            }

        </div>}
    </>
}
const mapStateToProps = state => ({
    localLoading: state.localLoading
});

const mapDispatchToProps = {
    showLocalLoading
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPayment);