import React from "react";
import Modal from "../HelpersComponents/Modal.Component";
import { Spin, Form, Input, Button, Row, Col } from "antd4";
// import "antd4/dist/antd.css";
import PropTypes from "prop-types";
import { registerUser } from "../../actions/userActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { showModal } from "../../actions/baseActions";
import ModalTypes from "../../enums/Modal.Types.Enum";
import { onlyNumeric } from "../../services/Utilities";

const regexp = /((?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$)/;

const RegistrationModal = (props) => {

  const { registrationModalVisible, showModal, loading, registerUser } = props;

  const handleSubmit = values => {
    registerUser({
      email: values.email,
      plainPassword: values.plainPassword,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone
    })
      .then(result => {
        if (result) {
          loginUser()
        }
      });
  };


  const loginUser = () => {
    showModal(ModalTypes.REGISTRATION_MODAL, false);
    showModal(ModalTypes.SIGN_IN_MODAL);
  }

  return (
    <Modal
      style={{ width: 1000 }}
      className={"SignIn Register"}
      visible={registrationModalVisible}
      onCancel={() => showModal(ModalTypes.REGISTRATION_MODAL, false)}
     
    >
      <Spin spinning={loading}>
        <h2 className={"SignInTitle text-center"}>Register</h2>
        <Form
          onFinish={handleSubmit}
          className="login-form"
          name={"registration_form"}
          layout={'vertical'}
        >
          <Row type="flex"
          >
            <Col type="flex"
              align="middle"  >
              <Form.Item
                label={"First name"}
                name={"firstName"}
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!"
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={"Last Name"}
                name={"lastName"}
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!"
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={"Email"}
                name={"email"}
                rules={[
                  {
                    required: true,
                    message: "Please input your Email address!",
                    type: "email"
                  }
                ]}
              >
                <Input autoComplete={"username email"} />
              </Form.Item>
            </Col>

            <Col type="flex"
            >
              <Form.Item
                label={"Phone number"}
                name={"phone"}
                rules={[
                  { required: true, message: "Please input your Phone number!" },
                  { min: 9, message: "Value must be greater than or equal to 9 !" },
                  { max: 13, message: "Value must be less than or equal to 13 !" }
                ]}
              >
                <Input
                  onKeyPress={onlyNumeric}
                />
              </Form.Item>
              <Form.Item
                label={"Password"}
                hasFeedback
                name={"plainPassword"}
                rules={[
                  { required: true, message: "Please input your Password!" },
                  () => ({
                    validator(rule, value) {
                      if (!value || value.match(regexp)) {
                        return Promise.resolve();
                      }

                      return Promise.reject("Password must contains at least 6 characters, including UPPER/lower case and numbers.");
                    }
                  })
                ]}
              >
                <Input type={"password"} />
              </Form.Item>
              <Form.Item
                label={"Confirm Password"}
                hasFeedback
                dependencies={["plainPassword"]}
                name={"confirmPassword"}
                rules={[
                  {
                    required: true,
                    message: "Please input Confirm Password!"
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("plainPassword") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject("Two passwords that you enter is inconsistent!");
                    }
                  })]}
              >
                <Input type={"password"} />
              </Form.Item>

            </Col>
          </Row>

          <Button type="primary" htmlType="submit" className="login-form-button">
            Register
          </Button>
          <p className={"DontHaveAccount"}>
            Have an account?
            <Link to={"#"} onClick={() => loginUser()}> Login </Link>
          </p>
        </Form>
      </Spin>
    </Modal>
  );
};


RegistrationModal.propTypes = {
  registrationModalVisible: PropTypes.bool.isRequired,
  registerUser: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  registrationModalVisible: state.modalVisible.registration,
  loading: state.loading
});

const mapDispatchToProps = {
  showModal,
  registerUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationModal);
