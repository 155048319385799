import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { removeLocation } from "../../actions/locationActions";
import { getCategories, updateFilterData } from "../../actions/baseActions";
import { connect } from "react-redux";
import numeral from "numeral";
import {
    updateFilter,
    resetFilter,
    showFilterModal,
} from "../../actions/filterActions";
import { showModal } from "../../actions/baseActions";
import { Slider, Checkbox } from "antd4";
import fArrow from "../../img/filter_arrow.svg";
import useDebounce from "../../settings/bounceHook";
import { getFilterDefaultData } from "../../api/Home.API";
import close from "../../img/close.png";
import filterPng from "../../img/filter.png";

const LeftMenu = ({
    filter,
    defaultFilterData,
    leftMenuVisible,
    featuresVisible,
    getCategories,
    address,
    isDelivery,
    resetFilter,
    onFilter,
    updateFilter,
    categories,
    showFilterModal,
    top,
    isAuth,
    customCategories,
    selectedRestaurant,
    status
}) => {
    const [openFilter, setOpenFilter] = useState({
        price: true,
        categories: true,
        dietFriendly: true,
    });

    const [Price, setPrice] = useState({
        min: filter.priceMin,
        max: filter.priceMax,
    });

    const [Search, setSearch] = useState("");
    const debouncedValue = useDebounce(Search, 300);
    const debouncedMin = useDebounce(Price.min, 300);
    const debouncedMax = useDebounce(Price.max, 300);
    const [isPreferences, setIsPreferences] = useState(false);

    useEffect(() => {
        updateFilter({ priceMin: Price.min, priceMax: Price.max });
    }, [debouncedMin, debouncedMax]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "auto",
        });
    }, []);

    useEffect(() => {
        updateFilter({ searchText: Search });
    }, [debouncedValue]);
    useEffect(() => {
        getFilterDefaultData().then(({ data }) => {
            data && setPrice(data?.price);
        });
        return () => {
            resetFilter();
            showFilterModal(false);
            updateFilter({
                selectedCategories: [],
                isOpenNow: false,
                searchText: "",
                dietFriendly: [],
            });
        };
    }, []);

    useEffect(() => {
        if (!address && isDelivery) {
            getCategories(false, true);
        } else if (address && isDelivery) {
            getCategories(isDelivery);
        } else if (!isDelivery) {
            getCategories(isDelivery);
        }
    }, [isDelivery]);

    useEffect(() => {
        if (isPreferences) {
            let data = JSON.parse(localStorage.getItem("Types"));
            handleFilterChange({
                ...data,
                selectedCategories: categories,
                preferred: 1
            });
        } else {
            handleFilterChange({
                selectedCategories: [],
                dietFriendly: [],
                kitchenType: [],
                preferred: 0
            });
        }
    }, [isPreferences]);

    const resetFilterLocal = () => {
        resetFilter();
        onFilter({});
        updateFilter({ dietFriendly: [] });
    };

    const handleFilterChange = (state, sendRequest = true) => {
        typeof state === "object" && updateFilter(state);
        const filterModel = Object.assign({}, filter, state);
        sendRequest && onFilter(filterModel);
    };

    const setCategories = (e, item) => {
        let newCategories = [...filter.selectedCategories];
        if (e.target.checked) {
            newCategories.push(item.slug);
        }
        if (!e.target.checked) {
            newCategories.map((i, j) => {
                if (i === item.slug) {
                    newCategories.splice(j, 1);
                }
            });
        }
        handleFilterChange({
            selectedCategories: newCategories,
        });
    };

    const setDietCategories = (e, item) => {
        let newCategories = [...filter.dietFriendly];
        if (e.target.checked) {
            newCategories.push(item.key);
        }
        if (!e.target.checked) {
            newCategories.map((i, j) => {
                if (i === item.key) {
                    newCategories.splice(j, 1);
                }
            });
        }
        handleFilterChange({
            dietFriendly: newCategories,
        });
    };

    const setFilterHandler = (e) => {
        setOpenFilter({
            ...openFilter,
            [e.target.name]: !openFilter[e.target.name],
        });
    };

    return (
        <div className="filter_wrapper" style={{ top: top, display: status ? 'flex' : 'none' }}>
            <div className="filter_menu">
                <div className="searchWrapper">
                    <input
                        type="text"
                        className={"search"}
                        placeholder={"Search"}
                        value={Search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>
            <div
                className={leftMenuVisible ? "LandingLeftMenu open" : "LandingLeftMenu"}
            >
                {featuresVisible && (
                    <div className="feature">
                        <h5>
                            <span>
                                <img src={filterPng} alt="Filter" /> Filter{" "}
                            </span>
                            <span
                                className={"clear"}
                                onClick={() => resetFilterLocal()}
                            >
                                Clear filter
                            </span>
                            <img
                                src={close}
                                className="close_menu"
                                alt="close"
                                onClick={() => showFilterModal(false)}
                            />
                        </h5>
                        {!Object.keys(selectedRestaurant).length && (
                            <p>
                                <Checkbox
                                    onChange={(evt) =>
                                        handleFilterChange({
                                            isOpenNow: evt.target.checked
                                                ? evt.target.checked
                                                : undefined,
                                        })
                                    }
                                    checked={filter.isOpenNow}
                                >
                                    Open now
                                </Checkbox>
                            </p>
                        )}
                        {isAuth && (
                            <p>
                                <Checkbox
                                    onChange={(evt) => setIsPreferences(evt.target.checked)}
                                    checked={isPreferences}
                                >
                                    My preferences
                                </Checkbox>
                            </p>
                        )}
                    </div>
                )}
                <div className="Price filter_item">
                    <div className="filter_header_wrapper">
                        <h5>Price</h5>
                        <img
                            className={openFilter.price ? "" : "imgRotate"}
                            src={fArrow}
                            alt="fArrow"
                            name="price"
                            onClick={setFilterHandler}
                        />
                    </div>
                    <div
                        className={
                            openFilter.price
                                ? "filter_body_wrapper "
                                : "filter_body_wrapper withHeight"
                        }
                    >
                        <div className={"pricesInputs"}>
                            <input
                                type="text"
                                value={numeral(Price.min).format("0,0[.]00")}
                                onChange={(e) => {
                                    setPrice({
                                        ...Price,
                                        min: e.target.value,
                                    });
                                }}
                            />
                            -
                            <input
                                type="text"
                                value={numeral(Price.max).format("0,0[.]00")}
                                onChange={(e) => {
                                    setPrice({
                                        ...Price,
                                        max: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <Slider
                            range
                            defaultValue={[Price.min, Price.max]}
                            value={[Price.min, Price.max]}
                            min={defaultFilterData.price.min}
                            max={defaultFilterData.price.max}
                            step={0.1}
                            tipFormatter={(value) => numeral(value).format("$ 0,0[.]00")}
                            onChange={(value) => {
                                if (value[0] < value[1]) {
                                    setPrice({
                                        min: value[0],
                                        max: value[1],
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={"Categories filter_item"}>
                    <div className="filter_header_wrapper">
                        <h5>Diet Friendly</h5>
                        <img
                            className={openFilter.dietFriendly ? "" : "imgRotate"}
                            src={fArrow}
                            alt="fArrow"
                            name="dietFriendly"
                            onClick={setFilterHandler}
                        />
                    </div>
                    <div
                        className={
                            openFilter.dietFriendly
                                ? "filter_body_wrapper "
                                : "filter_body_wrapper withHeight"
                        }
                    >
                        {address
                            ? defaultFilterData?.dietFriendly?.map((item, k) => {
                                return (
                                    <p key={k}>
                                        <Checkbox
                                            onClick={(e) => setDietCategories(e, item)}
                                            checked={
                                                !filter.dietFriendly.every((i) => i !== item.key)
                                            }
                                        >
                                            {item.name}
                                        </Checkbox>
                                    </p>
                                );
                            })
                            : null}
                    </div>
                </div>
                <div className={"Categories filter_item"}>
                    <div className="filter_header_wrapper">
                        <h5>Categories</h5>
                        <img
                            className={openFilter.categories ? "" : "imgRotate"}
                            src={fArrow}
                            alt="fArrow"
                            name="categories"
                            onClick={setFilterHandler}
                        />
                    </div>
                    <div
                        className={
                            openFilter.categories
                                ? "filter_body_wrapper "
                                : "filter_body_wrapper withHeight"
                        }
                    >
                        {customCategories?.length
                            ? customCategories.map((item, k) => {
                                return (
                                    <p key={k}>
                                        <Checkbox
                                            onClick={(e) => setCategories(e, item)}
                                            checked={
                                                !filter.selectedCategories.every(
                                                    (i) => i !== item.slug
                                                )
                                            }
                                        >
                                            {item.name} ({item.products.length})
                                        </Checkbox>
                                    </p>
                                );
                            })
                            : categories.map((item, k) => {
                                return (
                                    <p key={k}>
                                        <Checkbox
                                            onClick={(e) => setCategories(e, item)}
                                            checked={
                                                !filter.selectedCategories.every(
                                                    (i) => i !== item.slug
                                                )
                                            }
                                        >
                                            {item.name}
                                        </Checkbox>
                                    </p>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

LeftMenu.propTypes = {
    address: PropTypes.object,
    showModal: PropTypes.func.isRequired,
    leftMenuVisible: PropTypes.bool.isRequired,
    featuresVisible: PropTypes.bool.isRequired,
    onFilter: PropTypes.func.isRequired,
};

LeftMenu.defaultProps = {
    loadCategories: true,
    featuresVisible: true,
    customCategories: [],
    onFilter: (f) => f,
};

const mapStateToProps = (state) => ({
    loading: state.loading,
    leftMenuVisible: state.leftMenuVisible,
    categories: state.categories,
    filter: state.filter,
    isDelivery: state.isDelivery,
    isCatering: state.isCatering,
    defaultFilterData: state.defaultFilterData,
    localLoading: state.localLoading,
    address: state.currentLocation.address.address,
    isAuth: state.user.isAuth,
    selectedRestaurant: state.selectedRestaurant,
});

const mapDispatchToProps = {
    getCategories,
    removeLocation,
    showModal,
    updateFilter,
    resetFilter,
    updateFilterData,
    showFilterModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
