import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../styles/ShoppingLocation/ShoppingLocation.scss"

import OrderSummary from "./OrderSummary";
import { addUserInfo, changeStep } from "../../actions/checkoutAction";
import DeliveryOption from "./DeliveryOption";
import ArrivalTime from "./ArrivalTime";
import UserInfo from "./UserInfo";
import NavigateButtons from "./NavigateButton";
import { showModal } from "../../actions/baseActions";
import ModalTypes from "../../enums/Modal.Types.Enum";
const emailReg =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const phoneReg = "^[0-9() -]*$";
const numberReg = /^[A-Za-z\s]*$/

const ShoppingLocation = ({
    addUserInfo,
    changeStep,
    user,
    isAuth,
    location,
    showModal,
    isDelivery
}) => {
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (isAuth) {
            changeStep(3)
        }
        if (!Object.keys(location).length && isDelivery) {
            showModal(ModalTypes.LOCATION_MODAL, true)
        }
    },[isDelivery,location]);

    const [shippingValues, setShippingValues] = useState({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        phoneNumber: user.phone || "",
        orderNotes: "",
        createAccaunt: false
    });
    useEffect(() => {
        const { firstName, lastName, email, phoneNumber } = shippingValues

        if (firstName?.length > 0 && lastName?.length > 0 && email?.length > 5 && phoneNumber.length > 0) {
            setDisabled(false)
        } else {

            setDisabled(true)
        }
    }, [shippingValues]);

    const onHandleChange = (value, type) => {
        setShippingValues({
            ...shippingValues,
            [type]: value
        })
    }
    const changePhoneNumber = (value) => {
        if (value.match(phoneReg) && value.length < 16) {
            if (value.length === 1) {
                value = '(' + value;
                onHandleChange(value, "phoneNumber")
            }
            else if (value.length === 4) {
                value = value + ")";
                onHandleChange(value, "phoneNumber")
            }
            else if (value.length === 8) {
                value = value + " - ";
                onHandleChange(value, "phoneNumber")
            }
            else {
                onHandleChange(value, "phoneNumber")
            }
        }
    }
    const onKetDownHandler = (key) => {
        if (key === "Backspace" && shippingValues.phoneNumber.length === 2) {
            onHandleChange("", "phoneNumber")
        } else if (key === "Backspace") {
            let n = 0
            if (shippingValues.phoneNumber.substr(-1, 2) === "(" || shippingValues.phoneNumber.substr(-1, 2) === ")") {
                n = 2
            }
            else if (shippingValues.phoneNumber.substr(-1, 3) === " - ") {
                n = 4
            }
            else if (shippingValues.phoneNumber.substr(-1, 1) === " ") {
                n = 1
            }
            onHandleChange(shippingValues.phoneNumber.slice(0, shippingValues.phoneNumber.length - n), "phoneNumber")
        }
    }

    return (
        <div className=" shipping_payment_wrapper">
            <div className=" shipping_payment_inputs_area">
                <div className='sp_option_wrapper'>
                    <DeliveryOption />
                    <ArrivalTime />
                </div>
                <UserInfo />
                <div className="shiping_location_inputs_wrapper">
                    <div className="">
                        <p>First name *</p>
                        <input
                            type="text"
                            value={shippingValues.firstName}
                            onChange={e => e.target.value.match(numberReg) && onHandleChange(e.target.value, "firstName")}
                        />
                    </div>
                    <div>
                        <p>Last name *</p>
                        <input
                            type="text"
                            value={shippingValues.lastName}
                            onChange={e => e.target.value.match(numberReg) && onHandleChange(e.target.value, "lastName")}
                        />
                    </div>
                    <div>
                        <p>Email *</p>
                        <input
                            style={shippingValues.email.length === 0 || shippingValues.email.match(emailReg) ? {} : { border: "1px solid red" }}
                            type="email" value={shippingValues.email}
                            onChange={e => onHandleChange(e.target.value, "email")} />
                    </div>
                    <div className="phone_input_area">
                        <p>Phone *</p>
                        <input
                            type="text"
                            style={(!(shippingValues.phoneNumber.length > 0) || (shippingValues.phoneNumber.length === 15)) ? {} : { border: "1px solid red" }}
                            value={shippingValues.phoneNumber}
                            onKeyDown={e => onKetDownHandler(e.key)}
                            onChange={e => changePhoneNumber(e.target.value)} />
                        <div style={(!(shippingValues.phoneNumber.length > 0) || (shippingValues.phoneNumber.length === 15)) ? {} : { borderRight: "1px solid red", }} className="phone_country_code">+1</div>
                    </div>
                    <div>
                        <p>Order notes (optional)</p>
                        <textarea value={shippingValues.orderNotes} onChange={e => onHandleChange(e.target.value, "orderNotes")} />
                    </div>
                    <div style={{ flexDirection: 'row', width: '100%' }} className='sp_option_checkbox' onClick={() => onHandleChange(!shippingValues.createAccaunt, "createAccaunt")}>
                        <input type='checkbox' name='checkbox' value={shippingValues.createAccaunt} />
                        <label htmlFor="checkbox">Do you want to create account with this info?</label>
                    </div>
                </div>
                <NavigateButtons
                    onHandleClickForBack={() => { changeStep(1) }}
                    onHandleClick={() => {
                        addUserInfo(shippingValues);
                        changeStep(3)
                    }}
                    disabled={disabled}
                />
            </div>
            <OrderSummary />
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    isAuth: state.user.isAuth, location: state.currentLocation.address,
    isDelivery: state.isDelivery,
});

const mapDispatchToProps = {
    addUserInfo,
    changeStep,
    showModal
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShoppingLocation);
