import * as Http from "./Http.Client";

export const getRestaurantTypes = () => {
	return Http.Get(`api/v1/restaurant-types`, {}, true)
		.then(result => result ? result : false);
};
export const getKitchenTypes = () => {
	return Http.Get(`api/v1/kitchen-types`, {}, true)
		.then(result => result ? result : false);
};
export const getDietFriendlies = () => {
	return Http.Get(`api/v1/diet-friendlies`, {}, true)
		.then(result => result ? result : false);
};
export const setPreferencies = (data) => {
	console.log(data)
	return Http.Post(`api/v1/user/update/preferencies`, {}, data, true)
		.then(result => result ? result : false);
};