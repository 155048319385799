import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NotificationsModal from "./Notifications.Modal.Component";
import SignInModal from "./SignIn.Modal.Component";
import BasketModal from "./Basket.Modal.Component";
import RegistrationModal from "./Registration.Modal.Component";
import ForgotPasswordModal from "./Forgot.Password.Modal.Component";
import LocationModal from './Location.Modal';
import ChangePasswordModalComponent from './Change.Password.Modal.Component';

export const Modals = ({
	notificationModalVisible,
	signInModalVisible,
	registrationModalVisible,
	forgotPasswordModalVisible,
	basketModalVisible,
	changePasswordModalVisible,
	locationModalVisible, width
}) => {
	return (
		<>
			{notificationModalVisible && <NotificationsModal />}
			{basketModalVisible && <BasketModal />}
			{width > 800 && changePasswordModalVisible && <ChangePasswordModalComponent />}
			{width > 800 && signInModalVisible && (<SignInModal />)}
			{width > 800 && registrationModalVisible && (<RegistrationModal />)}
			{width > 800 && forgotPasswordModalVisible && (<ForgotPasswordModal />)}
			{locationModalVisible && (<LocationModal />)}
		</>
	)
};

Modals.propTypes = {
	notificationModalVisible: PropTypes.bool.isRequired,
	basketModalVisible: PropTypes.bool.isRequired,
	signInModalVisible: PropTypes.bool.isRequired,
	registrationModalVisible: PropTypes.bool.isRequired,
	forgotPasswordModalVisible: PropTypes.bool.isRequired,
	changePasswordModalVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
	notificationModalVisible: state.modalVisible.notification,
	basketModalVisible: state.modalVisible.basket,
	signInModalVisible: state.modalVisible.signIn,
	registrationModalVisible: state.modalVisible.registration,
	forgotPasswordModalVisible: state.modalVisible.forgotPassword,
	changePasswordModalVisible: state.modalVisible.changePassword,
	locationModalVisible: state.modalVisible.locationModal,
	width: state.width
});

export default connect(
	mapStateToProps,
	{},
)(Modals);
