import React, { useState } from "react";
import { connect } from "react-redux";
import "../../styles/RegularMenuStyle/regularMenu.scss";
import { showModalRegularMenu } from "../../actions/baseActions";
import LeftMenu from "../HomeComponents/LeftMenu.Component";
import LeftMenuComponent from "../HomeComponents/LeftMenu.Component";
import PopUP from "./PopUP"
import CateringFoodType from "../CateringSection/CateringFoodType";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import RestaurantList from "./Restaurant/RestaurantList";
import ProductList from "./Product/ProductList";
import RegularLocation from "./RegularLocation";
import Header from "./Header";
import { Spin } from "antd4";

const Regular = ({
    showModal,
    showModalRegularMenu,
    localLoading,
    width,
    filterModal
}) => {
    const [localIsFavorite, setLocalIsFavorite] = useState(false);

    return (<>
        {width < 635 && <LeftMenuComponent status={filterModal} top={window.pageYOffset} />}
        <Spin spinning={localLoading}>
            <>
                <ModalWrapper
                    height={window.pageYOffset - 170}
                    showModal={showModal}
                    closeModal={showModalRegularMenu}
                >
                    <PopUP height={window.pageYOffset + 270} setLocalIsFavorite={setLocalIsFavorite} localIsFavorite={localIsFavorite} />
                </ModalWrapper>
                <div className="regular_menu_wrapper" style={filterModal ? { marginTop: 0 } : {}}>
                    <RegularLocation />
                    <div className="menuPage">
                        <Header />
                        <CateringFoodType />
                        <div className="regular_menu_body_wrapper">
                            <LeftMenu />
                            <div className="regular_menu_body">
                                <RestaurantList setLocalIsFavorite={setLocalIsFavorite} localIsFavorite={localIsFavorite} />
                                <ProductList setLocalIsFavorite={setLocalIsFavorite} localIsFavorite={localIsFavorite} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Spin>
    </>
    );
};

const mapStateToProps = state => ({
    showModal: state.regularMenu.showModal,
    localLoading: state.localLoading,
    width: state.width,
    filterModal: state.filterModal,
});

const mapDispatchToProps = {
    showModalRegularMenu,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Regular);
