import React,{useEffect} from "react";
import { connect } from "react-redux";
// components

import "../../styles/AccountStyle/account.scss";
import AddressesSettings from "./Dashboard/Addresses";
import Dashboard from "./Dashboard/Dashboard";
import Settings from "./Dashboard/Settings";
import AccountPayment from "./Dashboard/AccountPayment";
import MyPreferences from "./Dashboard/MyPreferences";
import MyFavorites from "./Dashboard/MyFavorites";
import MyCoupons from "./Dashboard/MyCoupons";
import Faq from "./Dashboard/Faq";
import Security from "./Dashboard/Security";
import Terms from "./Dashboard/Terms";
import OrderHistory from "./Dashboard/OrderHistory";
import { changeSelectedSettings } from "./../../actions/baseActions";
import { Spin } from "antd4";

import SettingsForMobile from "./Dashboard/SettingsForMobile";

const Account = ({ user, selectedSettings, width, localLoading }) => {
	useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    }, []);
	const renderComponentFunc = () => {
		switch (selectedSettings) {
			case "settings":
				return <Settings user={user} />
			case "addresses":
				return <AddressesSettings />
			case "paymentMethods":
				return <AccountPayment />
			case "myPreferences":
				return <MyPreferences />
			case "myFavorites":
				return <MyFavorites />
			case "myCoupons":
				return <MyCoupons />
			case "faq":
				return <Faq />
			case "orderHistory":
				return <OrderHistory />
			case "privacyPolicy":
				return <Security />
			case "termsConditions":
				return <Terms />
			default:
				return <Settings user={user} />
		}
	}


	return (
		<Spin spinning={localLoading}>
			<div>
				{width > 650
					?
					<div className="accaunt_wrapper" >
						<Dashboard />
						<div className="setingsList_wrapper" style={{ width: width - 275 }}>
							{renderComponentFunc()}
						</div>
					</div>
					:
					<SettingsForMobile />
				}
			</div>
		</Spin>
	)
};


const mapStateToProps = (state) => ({
	user: state.user.data,
	selectedSettings: state.selectedSettings,
	localLoading: state.localLoading,
	width: state.width
});
const mapDispatchToProps = {
	changeSelectedSettings
}
export default connect(mapStateToProps, mapDispatchToProps)(Account);

