import * as React from 'react';
import { connect } from "react-redux"
import { DatePicker, TimePicker } from 'antd4'
import "../../styles/DatePicker/DatePicker.scss"
const ScheduledModal = ({ getValues }) => {

    const onChange = (e, dateString, type) => {
        getValues(dateString,type);
    }
    return <div className='date_picker_custom'>
        <DatePicker
            status="warning"
            onChange={(e, dateString) => onChange(e, dateString, "date")}
        />
        <TimePicker
            minuteStep={5}
            status="warning"
            onChange={(e, dateString) => onChange(e, dateString, "time")}
            format={'HH:mm'}
        />
    </div>
};

const mapStateToProps = state => ({

});
const mapDispatchToProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduledModal);