import initialState from "../store/_initialState";
import {RESTAURANTS} from "../actions/_actionTypes";

export const restaurantsReducer = (state = initialState.restaurants, action) => {

	if (action.type === RESTAURANTS.CHANGE_RESTAURANT) {
		return {...state,items:action.payload};
	}
	if (action.type === RESTAURANTS.SET_RESTAURANTS) {
		return action.payload;
	}
	if (action.type === RESTAURANTS.PAGINATE_RESTAURANT) {
		return {...state,items:[...state.items, ...action.payload.items]}
	}
	return state;
};

export const selectedRestaurantReducer = (state = initialState.selectedRestaurant, action) => {
	switch (action.type) {
		case RESTAURANTS.SET_SELECTED_RESTAURANT:
			return action.payload;
		
		case RESTAURANTS.CHANGE_FAVORITE:
			return {...state,restaurant:{...state.restaurant,isFavorite:!state.restaurant.isFavorite}};
		default:
			return state;
	}
};
export const selectedRestaurantProductReducer = (state = initialState.selectedRestaurantProduct, action) => {
	switch (action.type) {
		case RESTAURANTS.SELECTED_RESTAURANT_PRODUCT:
			return action.payload;
		
		default:
			return state;
	}
};
export const setRestaurantPageCountReducer = (state=initialState.pageCountRestaurant,action)=>{
	switch (action.type) {
		case RESTAURANTS.SET_RESTAURANT_PAGE_COUNT:
			return state + action.payload
		default:
			return state;
	}
}
